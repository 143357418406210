export const IconCopyPastEvent = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
<g clip-path="url(#clip0_2445_6229)">
  <path d="M81.2497 162.5C61.7247 162.5 45.833 146.608 45.833 127.083V41.6667H31.2497C18.608 41.6667 8.33301 51.9417 8.33301 64.5834V177.083C8.33301 189.725 18.608 200 31.2497 200H135.416C148.058 200 158.333 189.725 158.333 177.083V162.5H81.2497Z"/>
  <path d="M45.833 41.6667H31.2497C18.608 41.6667 8.33301 51.9417 8.33301 64.5834V177.083C8.33301 189.725 18.608 200 31.2497 200H83.333V162.5H81.2497C61.7247 162.5 45.833 146.608 45.833 127.083V41.6667Z" />
  <path d="M191.666 22.9167C191.666 10.2583 181.408 0 168.75 0H81.2497C68.5913 0 58.333 10.2583 58.333 22.9167V127.083C58.333 139.742 68.5913 150 81.2497 150H168.75C181.408 150 191.666 139.733 191.666 127.083V22.9167Z" />
  <path d="M125 0H81.2497C68.5913 0 58.333 10.2583 58.333 22.9167V127.083C58.333 139.742 68.5913 150 81.2497 150H125V0Z" />
</g>
<defs>
  <clipPath id="clip0_2445_6229">
    <rect width="200" height="200"/>
  </clipPath>
</defs>
</svg>`