import {Inject, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {DOCUMENT} from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CapchaService {
  constructor(@Inject(DOCUMENT) public document: Document) {}

  // @ts-ignore
  public async getSubmitToken(): Promise<string> {
    try {
      const recaptcha = await this.installCapchaProvider();
      return await recaptcha.execute(environment.googleRecapchaKey, { action: 'submit' });
    } catch (error) {
      console.error('Google Recaptcha error:', error);
    }
  }

  public installCapchaProvider(): Promise<any> {
    if ((window as any).grecaptcha) {
      return this.isRecapchaReady((window as any).grecaptcha);
    }

    const lib = this.document.createElement('script') as HTMLScriptElement;

    return new Promise((res, rej) => {
      lib.onload = () => {
        const recaptcha = (window as any).grecaptcha;

        if (!recaptcha) {
          rej();
          return;
        }

        this.isRecapchaReady(recaptcha).then(() => {
          res(recaptcha);
        });
      };

      lib.onerror = rej;

      this.document.head.appendChild(lib);
      lib.src = `https://www.google.com/recaptcha/api.js?render=${environment.googleRecapchaKey}`;
    });
  }

  private isRecapchaReady(recaptcha: any): Promise<any> {
    return new Promise((res) => {
      recaptcha.ready(() => {
        res(recaptcha);
      });
    });
  }
}
