import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  baseUrl: string = environment.APIURL;

  httpClient = new HttpClient(new HttpXhrBackend({
    build: () => new XMLHttpRequest()
  }));

  constructor(private http: HttpClient) {}

  public getInfo() {
    return this.http.get(`${this.baseUrl}api/info/application`);
  }
}
