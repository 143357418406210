<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions>
<mat-dialog-content class="mat-typography">
  <div 
    #desktopSearchFieldAdaptive
    class="adaptive__content" 
  >
    <div class="filters">
      <div class="location"></div>
      <mat-form-field class="dates">
        <div class="filter-fields">
            <mat-datepicker-toggle matIconSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
              </mat-datepicker-toggle>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" >
                <input matEndDate placeholder="End date">
              </mat-date-range-input>
              <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
    </mat-form-field>
      <input 
        #adaptiveSearchField
        type="text"
        placeholder="{{'SEARCH' | transloco}}"
        class="search-input">
    </div>
    <div class="filter-results">
    </div>
  </div>
</mat-dialog-content>