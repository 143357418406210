import { Component } from '@angular/core';

import { GlobalNotificationModel } from 'src/app/shared/models/global-notifications.model';
import { Animations } from './toast.animation';
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  selector: 'ui-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: Animations,
  imports: [
    CommonModule
  ]
})
export class ToastComponent {
  private TOAST_TIME_LIFE = 7000;

  public notificationList: Map<symbol, GlobalNotificationModel> = new Map();

  public set notification(notification: GlobalNotificationModel) {
    const notificationKey = Symbol();
    this.notificationList.set(notificationKey, notification);
    setTimeout(() => {
      this.notificationList.delete(notificationKey);
    }, this.TOAST_TIME_LIFE);
  }

  public trackIdentify(index: number, item: { key: symbol; value: GlobalNotificationModel }): symbol {
    return item.key;
  }

  public isArray(item: string[] | string): item is string[] {
    return Array.isArray(item);
  }
}
