import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncPipe, NgFor } from "@angular/common";
import { LocalizationModule } from '../localization';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from "@angular/material/icon";
import { User } from 'src/app/shared/models/user.model';
import { Store } from '@ngxs/store';
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/store/user/user.store';
import { IconEvents } from 'src/assets/icons/events';
import { IconFlag } from 'src/assets/icons/flag';
import { IconStudios } from 'src/assets/icons/studios';
import { IconSignOut } from 'src/assets/icons/sign-out';
import { home } from 'src/assets/icons/home';
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../shared/constants/safe-types.enum";
import { MatDividerModule } from '@angular/material/divider';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UserMeetDialogComponent } from '../user-dialog/meet/user-meet-dialog.component';
import { AuthContract } from "../../shared/contracts/auth.contract";
import { AuthTokenInfo } from "../../shared/models/auth-token.model";
import { Logout } from "../../store/user/user.actions";
import { NotificationTypes } from "../../shared/constants/notification-types.enum";
import { ToastService } from "../../shared/services/toast.service";
import { TranslocoService } from "@ngneat/transloco";
import { UserEventDialogComponent } from '../user-dialog/event/user-event-dialog.component';
import { Router } from "@angular/router";

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    LocalizationModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    SafePipe,
    NgFor,
    MatDividerModule,
    MatDialogModule
  ],
  providers: [
    ToastService,
  ],
})
export class UserActionsComponent {
  @Select(UserState.getPrimaryRoleName)
  public getRoleName$!: Observable<string>;
  @Input() user!: User;

  constructor(
      private router: Router,
      private authContract: AuthContract,
      private store: Store,
      private toast: ToastService,
      private transloco: TranslocoService,
      public dialog: MatDialog
  ) {}

  public IconEvents = IconEvents;
  public IconFlag = IconFlag;
  public IconStudios = IconStudios;
  public IconSignOut = IconSignOut;
  public IconHome = home;
  public SafeTypes = SafeTypes;

  openMeetDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(UserMeetDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  }

  openEventDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(UserEventDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  }

  logout() {
    this.authContract.logout().subscribe({
      next: (value: AuthTokenInfo) => {
        this.store.dispatch(new Logout());
        this.router.navigateByUrl('');
      },
      error: (error: any) => {
        const body = this.transloco.translate('ALERT_LOGOUT_FAILED');
        this.toast.show({ body, type: NotificationTypes.error });
      }
    });
  }

  dashboard() {
    this.router.navigateByUrl('dashboard');
  }
}
