<div class="dialog-wraper">
    <div class="dialog-header">
        <mat-dialog-content>
            <h3 class="title" #eventCreate>{{'EVENTS' | transloco}}</h3>
            <i mat-dialog-close class="close fas fa-times"></i>
        </mat-dialog-content>
    </div>

    <mat-dialog-actions class="dialog-actions">
        <button mat-button [mat-dialog-close]="'create'" class="create-event" (click)="createEvent(CreateEventTypes.COPY_FROM_PAST)">
            <p>Copy from past event</p>
            <div [innerHTML]="IconCopyPastEvent | safe: SafeTypes.html" class="action-icon copy"></div>
            <span>Create an event by making a copy of your past event with minor changes. You must be a promoter of an event you copy from.</span>
        </button>
        <button mat-button [mat-dialog-close]="'create'" class="create-event" (click)="createEvent(CreateEventTypes.CREATE_FROM_TEMPLATE)">
            <p>Create from template</p>
            <div [innerHTML]="IconCreateFromTemplate | safe: SafeTypes.html" class="action-icon template"></div>
            <span>Use a template to create a new event.</span>
        </button>
        <button mat-button [mat-dialog-close]="'create'" class="create-event" [routerLink]="'/create-new-event'">
            <p>Create a new event</p>
            <div [innerHTML]="IconCreateNewEvent | safe: SafeTypes.html" class="action-icon new"></div>
            <span>Start from scratch a new event. </span>
        </button>
    </mat-dialog-actions>
</div>