import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { skip } from "rxjs/operators";
import { LocalizationModule } from "../../localization";
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {emailPattern, loginAsEmailPattern, passwordPattern} from "../../../utils/control-utils";
import { AuthTokenInfo } from "../../../shared/models/auth-token.model";
import { LoginByToken } from "../../../store/user/user.actions";
import { NotificationTypes } from "../../../shared/constants/notification-types.enum";
import { AuthContract } from "../../../shared/contracts/auth.contract";
import { TranslocoService } from "@ngneat/transloco";
import { ToastService } from "../../../shared/services/toast.service";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";

@Component({
  standalone: true,
  selector: 'events-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  imports: [
    LocalizationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  providers: [
    ToastService,
  ],
})
export class SignInComponent implements OnInit, OnDestroy {
  @Output() signInSuccessed = new EventEmitter();
  @Output() displaySignUp = new EventEmitter();
  @Output() displayForgotPassword = new EventEmitter();

  public usernameControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(loginAsEmailPattern)]);
  public passwordControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(passwordPattern)]);

  public signinForm = new FormGroup({
    username: this.usernameControl,
    password: this.passwordControl,
  });

  public isLoading = false;

  hidePassword = true;
  displayIncorrectEmailOrPassword = false;

  //public resetByEmailController = new FormControl('', [Validators.required, Validators.pattern(emailPattern)]);
  //public resetByEmailController = new FormControl('');
  //forgotPasswordVisible = false;
  //forgotPasswordResultVisible = false;

  constructor(
    private authContract: AuthContract,
    private toast: ToastService,
    private store: Store,
    private transloco: TranslocoService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.signinForm.valueChanges.pipe(skip(2)).subscribe((data) => {
      if (this.displayIncorrectEmailOrPassword) {
        this.displayIncorrectEmailOrPassword = false;
        this.usernameControl.setErrors(null);
        this.passwordControl.setErrors(null);
      }
    });
  }

  ngOnDestroy(): void {
  }

  public async onFormSubmit(): Promise<void> {
    if (this.signinForm.invalid) {
      return;
    }

    this.login();
  }

  public login(): void {
    this.isLoading = true;

    const email = this.usernameControl.value;
    const password = this.passwordControl.value;

    this.authContract.login(email, password).subscribe({
          next: (value: AuthTokenInfo) => {
            if (value != null && value.accessToken != null) {
              this.store.dispatch(new LoginByToken(value.accessToken)).subscribe((result) => {
                if (result?.user?.authUser?.eventAccount?.accessLevel) {
                  const accessLevel = result?.user?.authUser?.eventAccount?.accessLevel;
                  if (accessLevel === 'PROMOTER') {
                    this.router.navigateByUrl('dashboard');
                  }
                }
              });
            }

            this.isLoading = false;
            this.signInSuccessed.emit();
          },
          error: (error: any) => {
            this.isLoading = false;

            if (error && error.error && error.error.code) {
              const code = error.error.code;
              if (code === 401) {
                this.displayIncorrectEmailOrPassword = true;
              }
            } else {
              const body = this.transloco.translate('ALERT_LOGIN_FAILED');
              this.toast.show({ body, type: NotificationTypes.error });
            }
          }
        }
    );
  }

  displaySignUpForm() {
    this.displaySignUp.emit();
  }

  displayForgotPasswordForm() {
    this.displayForgotPassword.emit();
  }

  /*resetPassword() {
    const email = (this.resetByEmailController.value as string).trim();
    this.authController.resetPassword(email).subscribe(
      (data) => {
        this.hideForgotPassword();
        if (data.reset) {
          this.toastr.success(data.message);
          this.close.emit();
        }
        if (!data.reset) {
          this.toastr.error(data.message);
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      },
    );
  }

  showForgotPassword() {
    this.forgotPasswordVisible = true;
  }

  hideForgotPassword() {
    this.resetByEmailController.reset();
    this.forgotPasswordVisible = false;
  }*/

}
