<form [formGroup]="signinForm" #loginForm="ngForm" (ngSubmit)="onFormSubmit()">
  <div class="signin-form-container">
    <div class="fields-container">
      <div class="field-item">
        <mat-form-field>
          <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
          <input
            matInput
            required
            [placeholder]="'EMAIL' | transloco"
            [formControl]="usernameControl"
          />
        </mat-form-field>
        <mat-error class="form-top-margin" *ngIf="loginForm.submitted && usernameControl.invalid">{{ 'EMAIL_NOT_CORRECT' | transloco }}</mat-error>
      </div>
      <div class="field-item form-top-margin">
        <mat-form-field>
          <mat-label>{{ 'PASSWORD' | transloco }}</mat-label>
          <input
            matInput
            required
            [type]="hidePassword ? 'password' : 'text'"
            [placeholder]="'PASSWORD' | transloco"
            [formControl]="passwordControl"
          />
          <mat-icon matSuffix class="hide-password-button" (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>
        <mat-error class="form-top-margin" *ngIf="loginForm.submitted && passwordControl.invalid">{{ 'ENTER_A_VALID_PASSWORD' | transloco }}</mat-error>
        <mat-error class="form-top-margin" *ngIf="displayIncorrectEmailOrPassword">{{ 'INCORRECT_EMAIL_ADDRESS_OR_PASSWORD' | transloco }}</mat-error>
      </div>
    </div>

    <div class="bottom-container">
      <div class="field-item">
        <button class="btn-7 w-100 signin-button" type="submit" [disabled]="!signinForm.valid || isLoading">{{ 'SIGN_IN' | transloco }}</button>
      </div>
      <div class="signup-container">
        <div class="field-row text-font">
          <div>{{ 'DO_NOT_HAVE_AN_ACCOUNT' | transloco }}</div>
          <a class="signup-link" (click)="displaySignUpForm()">{{ 'SIGN_UP' | transloco }}</a>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="forgot-password">
  <a class="forgot-password-link" (click)="displayForgotPasswordForm()">{{ 'FORGOT_PASSWORD' | transloco }}</a>
</div>
