import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ClickOutsideDirective } from './../../shared/directives/outsideclick.directive';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDateRangeInput, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Search } from "../../store/user/user.actions";
import { Store } from "@ngxs/store";

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    TranslocoModule,
    ClickOutsideDirective,
    MatButtonModule, 
    MatDialogModule
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('showHideSearch', [
      state('open', style({
        display: 'flex'
      })),
      state('close', style({
        display: 'none'
      })),
      transition('open => closed', [
        animate('0s')
      ]),
      transition('closed => open', [
        animate('0s')
      ]),
    ]),
    trigger('showHideSearchAdaptive', [
      state('open', style({
        display: 'flex',
        zIndex: '10'
      })),
      state('close', style({
        display: 'none',
        zIndex: '8'
      })),
      transition('open => closed', [
        animate('0s')
      ]),
      transition('closed => open', [
        animate('0s')
      ]),
    ])
  ]
})
export class SearchComponent {
  @ViewChild('desktopSearchField') private desktopSearchField!: ElementRef<HTMLInputElement>;
  @Input() isDiffUrl!: boolean;
  @Output() displaySearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() displaySearchAdaptive: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isOpen: boolean = false;
  public isOpenAdaptive: boolean = false;

  searchTimeout: any;

  constructor(
      public dialog: MatDialog,
      private store: Store,
  ) {}

  public toggleDesktopSearch(): void {
    this.isOpen = !this.isOpen;
    this.desktopSearchField.nativeElement.value = '';

    setTimeout(()=>{
      this.desktopSearchField.nativeElement.focus();
    },0);

    this.displaySearch.emit(this.isOpen);

    this.store.dispatch(new Search(''));
  }

  public onEnterPress (e: KeyboardEvent) {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      //console.dir(this.desktopSearchField.nativeElement?.value);
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      const search = this.desktopSearchField.nativeElement?.value.trim();
      this.store.dispatch(new Search(search));
    }, 500);
  }

  public toggleDesktopSearchAdaptive(): void {
    const dialogRef = this.dialog.open(DialogContent, {
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'search-dialog-adaptive',
  templateUrl: 'search-dialog-adaptive.html',
  styleUrls: ['./search-dialog-adaptive.scss'],
  standalone: true,
  imports: [
    MatDialogModule, 
    MatButtonModule,
    TranslocoModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonToggleModule
  ],
  providers: [TranslocoService]
})
export class DialogContent {
  @ViewChild('coordsValue') coordsValue!: ElementRef;
  public lat: number | undefined;
  public lng: number | undefined;
  
  getLocation(): void {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.coordsValue.nativeElement.value = this.lat && this.lng ? `${this.lat}, ${this.lng}` : ''
        },
        (error) => {
          this.coordsValue.nativeElement.value = `Error getting user location: ${error}`;
        }
      );
      return this.coordsValue ? this.coordsValue.nativeElement.value : '';
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }
}