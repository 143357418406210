
export interface User {
  id: number;
  firstName: string;
  fullName: string;
  lastName: string;
  phoneNumber: string;
  pictureUrl: string;
  email: string;
  dateOfBirth: string;
  contactName: string;
  eventAccount: EventUser | null;
  studioAccount: StudioUser | null;
  enabled: boolean;
  intercomHash: string;
  intercomUserId: string;
}

export interface StudioUser {
  id: number;
  email: string;
  roles: Role[];
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  gender: string;
  dateOfBirth: string;
  isActive: boolean;
}

export interface EventUser {
  id: number;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  selfPersonId: number;
  status: string;
  approval: string;
  phoneNumber: string;
  gender: string;
  birthDate: string;
  isActive: boolean;
  accessLevel: string;
}

export interface Role {
  id: number;
  name: string;
  application: string;
}

export enum StudioUserRoles {
  ADMIN = 'ROLE_ADMIN',
  MANAGER = 'ROLE_MANAGER',
  SCHOOL_ADMIN = 'ROLE_SCHOOL_ADMIN',
  USER = 'ROLE_USER',
  STUDIO_OWNER = 'ROLE_STUDIO_OWNER',
  STUDENT = 'ROLE_STUDENT',
  COACH = 'ROLE_COACH',
  STAFF = 'ROLE_STAFF',
}
