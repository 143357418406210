import { Component } from '@angular/core';
import { LocalizationModule } from '../../localization';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconCreateLicensedMeet } from 'src/assets/icons/createLicensedMeet';
import { IconCreatedLicensedMeetActive } from 'src/assets/icons/createdLicensedMeets-active';
import { IconCreatedLicensedMeetDisabled } from 'src/assets/icons/createdLicensedMeets-disabled';
import { SafePipe } from "../../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../../shared/constants/safe-types.enum";
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-meet-dialog.component.html',
  styleUrls: ['./user-meet-dialog.component.scss'],
  standalone: true,
  imports: [
    LocalizationModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    SafePipe,
    RouterModule
  ],
  providers: [TranslocoService]
})
export class UserMeetDialogComponent {
  constructor(private transloco: TranslocoService) {}

  public IconCreateLicensedMeet = IconCreateLicensedMeet;
  public IconCreatedLicensedMeetActive = IconCreatedLicensedMeetActive;
  public IconCreatedLicensedMeetDisabled = IconCreatedLicensedMeetDisabled;
  public SafeTypes = SafeTypes;

}
