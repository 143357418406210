<span #label (click)="isShowMenu = !isShowMenu" class="label">{{ 'LANGUAGE_SELF_LABEL' | transloco }}</span>
<i #icon2 class="arrow" (click)="isShowMenu = !isShowMenu" [innerHTML]="IconArrowDown | safe: SafeTypes.html"></i>

<div
  class="locales"
  [class.show]="isShowMenu"
  [class.is-admin]="isAdmin$ | async"
  uiOutsideClick
  [skipExcludes]="[label, icon2]"
  (outsideClick)="outsideClick($event)"
>
  <div
    class="locale"
    *ngFor="let locale of locales"
    (click)="changeLocale(locale.locale)"
    [class.is-raw-locale]="SupportedLocales.raw === locale.locale"
  >
    {{ locale.label }}
  </div>
</div>

<studio-user-settings [hidePanelsOnOpenSearch]="hidePanelsOnOpenSearch" [isDiffUrl]="isDiffUrl"></studio-user-settings>
