<ng-template [ngIf]="dialog">
  <i (click)="onOk()" class="close fas fa-times"></i>
  <div *ngIf="dialog.title" class="dialog-title">{{ dialog.title }}</div>
  <div class="dialog-text">{{ dialog.text }}</div>
  <div class="buttons">
    <ng-template [ngIf]="isConfirmTheme(dialog.theme)" [ngIfElse]="OkButton">
      <button class="mys-btn" (click)="onYes()">{{ 'YES' | transloco }}</button>
      <button class="mys-btn" (click)="onNo()">{{ 'NO' | transloco }}</button>
    </ng-template>

    <ng-template #OkButton>
      <button class="mys-btn" (click)="onOk()">{{ dialog.okButtonText || 'OK' }}</button>
    </ng-template>
  </div>
</ng-template>
