import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Venue } from '../models/venue.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    baseUrl: string = environment.APIURL;

    constructor(
        private http: HttpClient,
        private zone: NgZone,
        private toaster: ToastrService
    ) {}

    getEventsList(params?: any) {
        const path = 'api/public/events/filter';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }

    getEvent(id: number) {
      const path = `api/public/events/${id}`;
        return this.http
            .get<Venue[]>(`${this.baseUrl}${path}`)
    }

    getVenueFilter(venueName: string) {
        const path = 'api/v1/integrations/aau/venue-filter';
        const charNum = 10;
        return this.http
            .get<Venue[]>(`${environment.devUvLink}${path}?filter=${venueName}&limit=${charNum}`)
    }

    checkSubdomain(subdomainName: string) {
        const path = 'api/v1/integrations/aau/check-subdomain';
        return this.http
            .get<any>(`${environment.devUvLink}${path}?subdomain=${subdomainName}`)
    }

    

    copyEvent(eventCopyInfo: any) {
        const path = 'api/v1/integrations/aau/copy-event';
        return this.http.post(`${environment.devUvLink}${path}`, eventCopyInfo)
    }

    getGeocode(address: string) {
        const addressStr = address.split(' ').join('+');
        const key = 'AIzaSyA9SO3tPbXBHlapgNdeaMa6O6Edf-cRZZM';
        const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressStr}&key=${key}`;
        return this.http.get(googleUrl);
    }

    getReverseGeocode(latlng: string) {
        const key = 'AIzaSyA9SO3tPbXBHlapgNdeaMa6O6Edf-cRZZM';
        const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${key}`;
        return this.http.get(googleUrl);
    }

    getTopSportsList() {
        const path = 'api/public/events/top-sports';
        return this.http.get(`${this.baseUrl}${path}`);
    }

    getTopLeaguesList() {
        const path = 'api/public/events/top-leagues';
        return this.http.get(`${this.baseUrl}${path}`);
    }

    getTopCountriesList() {
        const path = 'api/public/events/top-counties';
        return this.http.get(`${this.baseUrl}${path}`);
    }

    getFilteredSportsList(name: string = '') {
        const params = {
            filter: {
                name: name,
                excludeIds: null,
            },
            page: 0,
            size: 1000,
        };

        const path = 'api/public/events/filter/sports';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }

    getFilteredLeaguesList(name: string = '') {
        const params = {
            filter: {
                name: name,
                excludeIds: null,
            },
            page: 0,
            size: 1000,
        };

        const path = 'api/public/events/filter/leagues';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }

    getFilteredCountriesList(name: string = '') {
        const params = {
            filter: {
                name: name,
                excludeIds: null,
            },
            page: 0,
            size: 1000,
        };

        const path = 'api/public/events/filter/counties';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }
}
