import { Directive, Output, EventEmitter, AfterViewInit, ViewContainerRef, Input, OnDestroy } from '@angular/core';
import {Subscription, Observable, fromEvent} from 'rxjs';
import {first, filter, debounceTime, map, distinctUntilChanged, startWith, tap} from 'rxjs/operators';
import {isOwnClick} from "../../utils/owner-click.util";
import {Select} from "@ngxs/store";
import {UserState} from "../../store/user/user.store";

@Directive({
  selector: '[uiOutsideClick]',
  standalone: true,
})
export class OutsideClickDirective implements AfterViewInit, OnDestroy {
  private width = 0;

  @Output()
  private outsideClick = new EventEmitter<boolean>();

  @Input()
  public skipExcludes: unknown[] = [];

  @Input()
  public lazySkipExcludes: Observable<HTMLElement>[] = [];

  private allExcludes: HTMLElement[] = [];

  private clickSubscribe!: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
  ) {
    this.getWidth();
  }

  public ngOnDestroy(): void {
    if (this.clickSubscribe) {
      this.clickSubscribe.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    this.allExcludes = [...this.skipExcludes] as HTMLElement[];
    this.listenOutSideClick();

    this.lazySkipExcludes.forEach((lazySkipExcludes) => {
      lazySkipExcludes
        .pipe(
          filter((excludeElement) => !!excludeElement),
          first(),
        )
        .subscribe((excludeElement) => {
          this.allExcludes = [...this.allExcludes, excludeElement];
          this.listenOutSideClick();
        });
    });
  }

  private listenOutSideClick(): void {
    if (this.clickSubscribe) {
      this.clickSubscribe.unsubscribe();
    }

    this.clickSubscribe = isOwnClick(this.viewContainerRef.element.nativeElement, this.allExcludes).subscribe((isOwn) => {
      if (this.width <= 1562) {
        this.outsideClick.emit(isOwn);
      }
    });
  }

  private getWidth(): void {
    fromEvent(window, 'resize').pipe(
      debounceTime(200),
      map(() => window.innerWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth),
      tap((width) => {
        this.width = width;
      }),
    ).subscribe();
  }
}
