export const IconYoutube = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<g clip-path="url(#clip0_2087_426)">
  <path d="M10.9707 14.1848L14.4499 11.8957L10.9707 9.60156V14.1848Z" fill="white"/>
  <path d="M12.0045 0C5.37524 0 0.00195312 5.37329 0.00195312 12.0025C0.00195312 18.6318 5.37524 24.0051 12.0045 24.0051C18.6337 24.0051 24.007 18.6318 24.007 12.0025C24.007 5.37329 18.6287 0 12.0045 0ZM18.2843 9.68306V14.5651C18.2843 15.5729 17.4689 16.3883 16.4662 16.3883H7.92261C6.91987 16.3883 6.10451 15.5729 6.10451 14.5651V9.68306C6.10451 8.67525 6.91987 7.85989 7.92261 7.85989H16.4611C17.4689 7.86495 18.2843 8.68031 18.2843 9.68306Z" fill="white"/>
</g>
<defs>
  <clipPath id="clip0_2087_426">
    <rect width="24" height="24" fill="white" transform="translate(0.00195312)"/>
  </clipPath>
</defs>
</svg>`