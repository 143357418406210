<ul class="top-menu" *ngIf="!isDiffUrl">
  <ng-container *ngFor="let m of menu">
    <li *ngIf="m.show">
      <!--<a
        *ngIf="(!m?.subMenu?.length && m?.show !== false) && !m.link.includes(baseUrl) && !m.outside; else externalLink"
        routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: m?.exact}" 
         [routerLink]="m.link"
      >
        <div class="name">{{ m.name | transloco }}</div>
      </a>-->
        <a *ngIf="(!m?.subMenu?.length && m?.show !== false) && !m.link.includes(baseUrl) && !m.outside; else externalLink" [routerLink]="m.link">
            <div class="name">{{ m.name | transloco }}</div>
        </a>

      <ng-template #externalLink>
        <a [href]="m.link" target="_blank">
          <div class="name">{{ m.name | transloco }}</div>
        </a>
      </ng-template>

      <a *ngIf="m?.subMenu?.length && m?.show !== false" (click)="m.active = !m.active"
         class="sub-m"
         [class.active-sub]="router.isActive(router.createUrlTree(m.links), false) || m.active">
        <i *ngIf="m.icon" class="fas {{m.icon}}"></i>
        {{ m.name | transloco }}
      </a>

      <ul *ngIf="m?.subMenu?.length && m?.show">
        <ng-container *ngFor="let s of m?.subMenu">
          <li *ngIf="s.show !== false">
            <a routerLinkActive="active" [routerLink]="s.link">{{ s.name | transloco }}</a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ng-container>
</ul>