import { Component, Input } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { UserState } from "../../store/user/user.store";
import { User } from "../../shared/models/user.model";
import { AsyncPipe, CommonModule, NgIf } from "@angular/common";
import { LocalizationModule } from "../localization";
import { MatIconModule } from "@angular/material/icon";
import { SignInSignUpComponent } from "../signin-signup/signin-signup.component";
import { UserActionsComponent } from "../user-actions/user-actions.component";
import { MatButtonModule } from "@angular/material/button";
import { Dialog, DialogModule } from "@angular/cdk/dialog";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { OverlayModule } from "@angular/cdk/overlay";
import { IconStore } from "src/assets/icons/mobile-menu-panel/store";
import { IconAthletes } from "src/assets/icons/mobile-menu-panel/athletes";
import { IconEvents } from "src/assets/icons/mobile-menu-panel/events";
import { IconLeagues } from "src/assets/icons/mobile-menu-panel/leagues";
import { IconNews } from "src/assets/icons/mobile-menu-panel/news";
import { IconRankings } from "src/assets/icons/mobile-menu-panel/rankings";
import { IconStudios } from "src/assets/icons/mobile-menu-panel/studios";
import { IconTickets } from "src/assets/icons/mobile-menu-panel/tickets";
import { IconTV } from "src/assets/icons/mobile-menu-panel/tv";
import { SafePipe } from "src/app/shared/pipes/safe.pipe";
import { SafeTypes } from "src/app/shared/constants/safe-types.enum";
import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { RouterModule } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: "studio-user-settings",
  standalone: true,
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
  imports: [
    AsyncPipe,
    LocalizationModule,
    MatIconModule,
    NgIf,
    UserActionsComponent,
    MatButtonModule,
    MatDialogModule,
    DialogModule,
    SignInSignUpComponent,
    CommonModule,
    OverlayModule,
    SafePipe,
    RouterModule,
  ],
})
export class UserSettingsComponent {
  @Input() isDiffUrl!: boolean;

  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.getPrimaryRoleName)
  public getRoleName$!: Observable<string>;
  @Input() hidePanelsOnOpenSearch!: boolean;

  constructor(private dialog: MatDialog, private auth: AuthService) {}

  ngOnInit() {
    this.auth.openSignInDialog$.subscribe((open) => {
      if (open) {
        this.signIn();
      }
    });
  }
  public baseUrl = environment.BASEURL.slice(
    environment.BASEURL.indexOf("/") + 2,
    -1
  );
  public isOpen = false;
  public SafeTypes = SafeTypes;
  public IconStore = IconStore;
  public IconAthletes = IconAthletes;
  public IconEvents = IconEvents;
  public IconLeagues = IconLeagues;
  public IconNews = IconNews;
  public IconRankings = IconRankings;
  public IconStudios = IconStudios;
  public IconTickets = IconTickets;
  public IconTV = IconTV;

  public signIn(): void {
    //this.dialog.addCustomDialog(SignInSignUpComponent, null);
    const dialogRef = this.dialog.open(SignInSignUpComponent, {
      autoFocus: "dialog",
      restoreFocus: false,
    });

    /*dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });*/
  }

  public filterPosition = [
    new ConnectionPositionPair(
      { originX: "start", originY: "bottom" },
      { overlayX: "end", overlayY: "top" },
      102,
      28,
      "mobile-menu-panel"
    ),
  ];
}
