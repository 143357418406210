export const IconCreateFromTemplate = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
<g clip-path="url(#clip0_2445_6234)">
  <path d="M118.75 25H22.9167C10.25 25 0 35.25 0 47.9167V152.083C0 164.75 10.25 175 22.9167 175H77.8333C73.3333 165.5 70.8333 154.917 70.8333 143.75L70.9167 141.667H31.25C27.8333 141.667 25 138.833 25 135.417C25 132 27.8333 129.167 31.25 129.167H72.3333C73.3333 124.083 74.9167 119.167 76.9167 114.583H31.25C27.8333 114.583 25 111.75 25 108.333C25 104.917 27.8333 102.083 31.25 102.083H84C87.75 96.6667 92.3333 91.75 97.4167 87.5H31.25C27.8333 87.5 25 84.6667 25 81.25C25 77.8333 27.8333 75 31.25 75H110.417C112.167 75 113.667 75.6667 114.833 76.8333C123.083 73.25 132.167 71.1667 141.667 70.9167V47.9167C141.667 35.25 131.417 25 118.75 25Z"/>
  <path d="M102.083 16.6667H92.9163C90.1663 7.08333 81.333 0 70.833 0C60.333 0 51.4997 7.08333 48.7497 16.6667H39.583C36.1663 16.6667 33.333 19.5 33.333 22.9167V39.5833C33.333 47.5833 39.9163 54.1667 47.9163 54.1667H93.7497C101.75 54.1667 108.333 47.5833 108.333 39.5833V22.9167C108.333 19.5 105.5 16.6667 102.083 16.6667Z"/>
  <path d="M114.833 76.8333C108.5 79.5833 102.667 83.175 97.4167 87.5H31.25C27.8333 87.5 25 84.6667 25 81.25C25 77.8333 27.8333 75 31.25 75H110.417C112.167 75 113.667 75.6667 114.833 76.8333Z" fill="white"/>
  <path d="M84 102.083C81.25 106 78.8333 110.167 76.9167 114.583H31.25C27.8333 114.583 25 111.75 25 108.333C25 104.917 27.8333 102.083 31.25 102.083H84Z" fill="white"/>
  <path d="M72.3333 129.167C71.5 133.25 71 137.417 70.9167 141.667H31.25C27.8333 141.667 25 138.833 25 135.417C25 132 27.8333 129.167 31.25 129.167H72.3333Z" fill="white"/>
  <path d="M33.3333 25H22.9167C10.25 25 0 35.25 0 47.9167V152.083C0 164.75 10.25 175 22.9167 175H69.9167V141.667H31.25C27.8333 141.667 25 138.833 25 135.417C25 132 27.8333 129.167 31.25 129.167H69.9167V114.583H31.25C27.8333 114.583 25 111.75 25 108.333C25 104.917 27.8333 102.083 31.25 102.083H69.9167V87.5H31.25C27.8333 87.5 25 84.6667 25 81.25C25 77.8333 27.8333 75 31.25 75H69.9167V54.1667H47.9167C39.9167 54.1667 33.3333 47.5833 33.3333 39.5833V25Z/>
  <path d="M69.9163 0.0166016C59.8247 0.416602 51.4247 7.35827 48.7497 16.6666H39.583C36.1663 16.6666 33.333 19.4999 33.333 22.9166V24.9999V39.5833C33.333 47.5833 39.9163 54.1666 47.9163 54.1666H69.9163V0.0166016Z"/>
  <path d="M69.9167 75H31.25C27.8333 75 25 77.8333 25 81.25C25 84.6667 27.8333 87.5 31.25 87.5H69.9167V75Z" fill="white"/>
  <path d="M69.9167 102.083H31.25C27.8333 102.083 25 104.917 25 108.333C25 111.75 27.8333 114.583 31.25 114.583H69.9167V102.083Z" fill="white"/>
  <path d="M69.9167 129.167H31.25C27.8333 129.167 25 132 25 135.417C25 138.833 27.8333 141.667 31.25 141.667H69.9167V129.167Z" fill="white"/>
  <path d="M143.75 200C112.733 200 87.5 174.775 87.5 143.75C87.5 112.725 112.733 87.5 143.75 87.5C174.767 87.5 200 112.725 200 143.75C200 174.775 174.767 200 143.75 200Z"/>
  <path d="M143.75 175C139.15 175 135.417 171.275 135.417 166.667V120.833C135.417 116.225 139.15 112.5 143.75 112.5C148.35 112.5 152.084 116.225 152.084 120.833V166.667C152.084 171.275 148.35 175 143.75 175Z" fill="white"/>
  <path d="M166.667 152.083H120.833C116.233 152.083 112.5 148.358 112.5 143.75C112.5 139.142 116.233 135.417 120.833 135.417H166.667C171.267 135.417 175 139.142 175 143.75C175 148.358 171.267 152.083 166.667 152.083Z" fill="white"/>
  <path d="M143.75 87.5C112.733 87.5 87.5 112.725 87.5 143.75C87.5 174.775 112.733 200 143.75 200V175C139.15 175 135.417 171.275 135.417 166.667V152.083H120.833C116.233 152.083 112.5 148.358 112.5 143.75C112.5 139.142 116.233 135.417 120.833 135.417H135.417V120.833C135.417 116.225 139.15 112.5 143.75 112.5V87.5Z"/>
  <path d="M143.75 152.083H135.417V166.667C135.417 171.275 139.15 175 143.75 175V152.083ZM143.75 112.5C139.15 112.5 135.417 116.225 135.417 120.833V135.417H143.75V112.5Z" fill="white"/>
  <path d="M143.75 135.417H135.417H120.833C116.233 135.417 112.5 139.142 112.5 143.75C112.5 148.358 116.233 152.083 120.833 152.083H135.417H143.75V135.417Z" fill="white"/>
</g>
<defs>
  <clipPath id="clip0_2445_6234">
    <rect width="200" height="200" fill="white"/>
  </clipPath>
</defs>
</svg>`