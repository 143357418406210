import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';
import { StoreContract } from 'src/app/shared/contracts/store.contract';
import { SetStudios } from 'src/app/pages/store/store/store.actions';
import { OnlineStoreState } from 'src/app/pages/store/store/store.state';
import { MenuServices } from 'src/app/shared/services/menu.services';

@Injectable()
export class ProductsGuard implements CanActivate {
  constructor(
    private store: Store,
    private storeContracts: StoreContract,
    private menuServices: MenuServices
  ) {}

  public canActivate(): Observable<true> {
    this.menuServices.offMenu();
    const studios = this.store.selectSnapshot(OnlineStoreState.studios);

    if (studios.length) {
      return of(true);
    }

    return this.storeContracts
      .getStudiosWithPublicStore()
      .pipe(switchMap((studios) => this.store.dispatch(new SetStudios(studios)).pipe(mapTo(true as unknown as true))));
  }
}
