import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalizationModule } from '../../localization';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconCreateFromTemplate } from 'src/assets/icons/events/createFromTemplate';
import { IconCopyPastEvent } from 'src/assets/icons/events/copyPastEvent';
import { IconCreateNewEvent } from 'src/assets/icons/events/createNewEvent';
import { SafePipe } from "../../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../../shared/constants/safe-types.enum";
import { Router, RouterModule } from '@angular/router';
import { CreateEventTypes } from 'src/app/shared/constants/events.enum';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-event-dialog.component.html',
  styleUrls: ['./user-event-dialog.component.scss'],
  standalone: true,
  imports: [
    LocalizationModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    SafePipe,
    RouterModule
  ],
  providers: [TranslocoService]
})
export class UserEventDialogComponent implements AfterViewInit {
  @ViewChild('eventCreate') eventCreate!: ElementRef;

  constructor(
    private transloco: TranslocoService,
    public router: Router
  ) {}

  ngAfterViewInit() {
    this.eventCreate.nativeElement.click();
  }

  public IconCreateFromTemplate = IconCreateFromTemplate;
  public IconCopyPastEvent = IconCopyPastEvent;
  public IconCreateNewEvent = IconCreateNewEvent;
  public SafeTypes = SafeTypes;
  public CreateEventTypes = CreateEventTypes;

  public createEvent(type: string) {
    if (type === CreateEventTypes.CREATE_NEW_EVENT) this.router.navigateByUrl('create-new-event')
    else this.router.navigateByUrl(`create-event?type=${type}`, {state: {type: type}})
  }
}
