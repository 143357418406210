<mat-icon
  class="apps-icon"
  [ngClass]="{'invisible': hidePanelsOnOpenSearch || isDiffUrl}"
  (click)="isOpen = !isOpen"
  type="button"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin"
>apps</mat-icon>

<ng-template
  class="mobile-menu-panel"
  cdkConnectedOverlay 
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true" 
  [cdkConnectedOverlayOrigin]="trigger" 
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="isOpen = false" 
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayPositions]="filterPosition"
>
  <div class="mobile-menu-panel__item" [routerLink]="'/events'" (click)="isOpen = false">
    <span [innerHTML]="IconEvents | safe: SafeTypes.html"></span>
    <span class="title">{{'EVENTS' | transloco}}</span>
  </div>
  <div class="mobile-menu-panel__item" [routerLink]="'/hub-studios'" (click)="isOpen = false">
    <span [innerHTML]="IconStudios | safe: SafeTypes.html"></span>
    <span class="title">{{'STUDIOS' | transloco}}</span>
  </div>
  <a class="mobile-menu-panel__item" [href]="'https://report.'+baseUrl+'#rankings'" target="_blank">
    <span [innerHTML]="IconRankings | safe: SafeTypes.html"></span>
    <span class="title">{{'RANKINGS' | transloco}}</span>
  </a>
  <div class="mobile-menu-panel__item" [routerLink]="'/store/list'" (click)="isOpen = false">
    <span [innerHTML]="IconStore | safe: SafeTypes.html"></span>
    <span class="title">{{'STORE' | transloco}}</span>
  </div>
  <div class="mobile-menu-panel__item" [routerLink]="'/tv'" (click)="isOpen = false">
    <span [innerHTML]="IconTV | safe: SafeTypes.html"></span>
    <span class="title">{{'TV' | transloco}}</span>
  </div>
  <div class="mobile-menu-panel__item" [routerLink]="'/athletes'" (click)="isOpen = false">
    <span [innerHTML]="IconAthletes | safe: SafeTypes.html"></span>
    <span class="title">{{'ATHLETES' | transloco}}</span>
  </div>
  <div class="mobile-menu-panel__item" [routerLink]="'/leagues'" (click)="isOpen = false">
    <span [innerHTML]="IconLeagues | safe: SafeTypes.html"></span>
    <span class="title">{{'LEAGUES' | transloco}}</span>
  </div>
  <div class="mobile-menu-panel__item" [routerLink]="'/news'" (click)="isOpen = false">
    <span [innerHTML]="IconNews | safe: SafeTypes.html"></span>
    <span class="title">{{'NEWS' | transloco}}</span>
  </div>
  <div class="mobile-menu-panel__item" [routerLink]="'/#'" (click)="isOpen = false">
    <span [innerHTML]="IconTickets | safe: SafeTypes.html"></span>
    <span class="title">{{'TICKETS' | transloco}}</span>
  </div>
</ng-template>

<div *ngIf="(authUser$ | async) as user; else notAuthUserTop" class="wrap-user-settings">
  <app-user-actions [user]="user"></app-user-actions>
</div>

<ng-template #notAuthUserTop>
  <button class="signin-button" type="button" (click)="signIn()">{{ 'SIGN_IN' | transloco }}</button>
  <!--<div (click)="signIn()" class="signin">{{ 'SignIn' | transloco }}</div>-->
</ng-template>