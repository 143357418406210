<div class="uv-line-1"></div>
<div class="wrap-footer">
  <div class="footer">
    <div>
      <div [innerHTML]="IconUvFooter | safe: SafeTypes.html" class="logo"></div>
      <div class="uv-label-9">Terms of use</div>
      <div class="uv-label-9">Privacy policy</div>
      <div class="uv-label-9">Cookies</div>
    </div>

    <div class="section-company">
      <div class="uv-label-7">Company and Products</div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com" target="_blank">Uventex Sports Hub</a>
      </div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/studio-management" target="_blank">Uventex Studios</a>
      </div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/event-management" target="_blank">Uventex Events</a>
      </div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/videos" target="_blank">Uventex TV</a>
      </div>
    </div>

    <div class="section-company">
      <div class="uv-label-7">Community</div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/blog" target="_blank">Uventex Sports Hub blog</a>
      </div>
      <div class="uv-label-8">
        <a href="https://www.uventexlabs.com/brand" target="_blank">Design and branding</a>
      </div>
    </div>

    <div class="section-company">
      <div class="uv-label-7">Support</div>
      <a class="uv-label-8" href="mailto:support@uventex.com">
        support@uventex.com
      </a>

      <div class="social-media">
        <a href="https://www.facebook.com/MYuventex" target="_blank">
          <div [innerHTML]="IconFacebook | safe: SafeTypes.html"></div>
        </a>
        <a href="https://www.youtube.com/channel/UC5pnyfSfNG6altM86pxtB3w" target="_blank">
          <div [innerHTML]="IconYoutube | safe: SafeTypes.html"></div>
        </a>
        <a href="https://www.instagram.com/uventexlabs/" target="_blank">
          <div [innerHTML]="IconInstagram | safe: SafeTypes.html"></div>
        </a>
        <a href="https://twitter.com/uventexlabs" target="_blank">
          <div [innerHTML]="IconTwitter | safe: SafeTypes.html"></div>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="wrap-company">
  <div class="company">
    <div>Designed and created by Uventex Labs</div>
    <div>Since 2015</div>
  </div>
</div>