import { Injectable } from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {StoreContract} from "src/app/shared/contracts/store.contract";

@Injectable()
export class ProductResolver implements Resolve<any> {
  constructor(
    private storeContracts: StoreContract,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const {studioId, categoryId} = route.params;
    return forkJoin([
      this.storeContracts.getStoreProduct(studioId, categoryId),
      this.storeContracts.getProductStocks(studioId, categoryId)
    ]);
  }
}
