export enum PaymentProviders {
  none = 'NONE',
  cash = 'CASH',
  check = 'CHECK',
  atm = 'ATM',
  stripe = 'STRIPE',
  qiwi = 'QIWI',
  authorizeNet = 'AUTHORIZE_NET',
  liqpay = 'LIQPAY',
  forte = 'FORTE',
  paypal = 'PAYPAL',
  paysafe = 'PAYSAFE',
  stackPay = 'STACK_PAY',
}
