import { FilterOptions } from "../../shared/models/filter-options.model";

export class ToggleSidePanel {
  static readonly type = '[User] Toggle Side Panel';
  constructor(public toggleSidePanel: boolean) {}
}

export class Login {
  static readonly type = '[User] login';
  constructor(public username: string, public password: string, public captchaToken: string) {}
}

export class LoginByToken {
  static readonly type = '[User] Login by Token';
  constructor(public token: string) {}
}

export class Logout {
  static readonly type = '[User] logout';
}

export class Search {
  static readonly type = '[User] Search';
  constructor(public search: string) {}
}

export class SetFilters {
  static readonly type = '[User] SetFilters';
  constructor(public filters: FilterOptions) {}
}