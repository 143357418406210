<div class="dialog-wraper">
    <div class="dialog-header">
        <mat-dialog-content>
            <h3 class="title">{{'MEETS' | transloco}}</h3>
            <i mat-dialog-close class="close fas fa-times"></i>
        </mat-dialog-content>
    </div>

    <mat-dialog-actions class="dialog-actions">
        <button mat-button [mat-dialog-close]="'view'" class="view" disabled>
            <div [innerHTML]="IconCreatedLicensedMeetDisabled | safe: SafeTypes.html" class="action-icon"></div>
            <p>My Licensed AAU meets</p>
            <span>For already in-progress or created AAU licensed meets</span>
        </button>
        <button mat-button [mat-dialog-close]="'create'" class="create" [routerLink]="'/meets'">
            <div [innerHTML]="IconCreateLicensedMeet | safe: SafeTypes.html" class="action-icon"></div>
            <p>Create Licensed AAU meet +</p>
            <span>License verification required</span>
        </button>
    </mat-dialog-actions>
</div>