import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  importProvidersFrom,
  Inject, OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import {
  NavigationEnd,
  NavigationStart,
  provideRouter,
  Router,
  RouterModule,
} from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { CommonModule } from "@angular/common";
import { TranslocoService } from "@ngneat/transloco";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { NgxsModule, Select, StateStream, Store } from "@ngxs/store";
import { OnlineStoreState } from "./app/pages/store/store/store.state";
import { ProductsGuard } from "./app/pages/store/products.guard";
import { environment } from "./environments/environment";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { ProductResolver } from "./app/pages/store/product/product.resolver";
import { provideAnimations } from "@angular/platform-browser/animations";
import { BehaviorSubject, Observable } from "rxjs";
import { LeftPanelComponent } from "./app/components/left-panel/left-panel.component";
import { LocalizationModule } from "./app/components/localization";
import { LocalSelectorComponent } from "./app/components/local-selector/local-selector.component";
import { SidebarService } from "./app/shared/services/sidebar.services";
import { DialogService } from "./app/shared/services/dialog.service";
import { DialogAnimation } from "./app/components/wrapper/wrapper.animation";
import { IconUventex } from "./assets/icons/uventex";
import { SafeTypes } from "./app/shared/constants/safe-types.enum";
import { SafePipe } from "./app/shared/pipes/safe.pipe";
import { FooterComponent } from "./app/components/footer/footer.component";
import { StoreList } from "./app/store";
import { OutsideClickDirective } from "./app/shared/directives/outside-click.directive";
import { IconBars } from "./assets/icons/bars";
import { AuthTokenResolver } from "./app/pages/auth/authtoken.resolver";
import { AuthComponent } from "./app/pages/auth/auth.component";
import { UserSettingsComponent } from "./app/components/user-settings/user-settings.component";
import { HeaderComponent } from "./app/components/header/header.component";
import { FilterComponent, FilterType } from "./app/components/filter/filter.component";
import { SearchComponent } from "./app/components/search/search.component";
import { IconCalendar } from "./assets/icons/calendar";
import { IconLocation } from "./assets/icons/location";
import { IconSearch } from "./assets/icons/search";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDateRangePicker,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import * as moment from "moment";
import { MatIconModule } from "@angular/material/icon";
import { LoaderInterceptor } from "./app/shared/http-interceptor/loader.interceptor";
import { AuthInterceptor } from "./app/shared/http-interceptor/auth.interceptor";
import { ClickOutsideDirective } from "./app/shared/directives/outsideclick.directive";
import { CarouselModule } from "ngx-owl-carousel-o";
import { DictionariesContract } from "./app/shared/contracts/dictionaries.contract";
import { UserState } from "./app/store/user/user.store";
import { User } from "./app/shared/models/user.model";
import { ComingSoonComponent } from "./app/components/empty-page/coming-soon.component";
import { EventRegistrationGuard } from "./app/common/guards/event-registration.guard";
import { WINDOW } from "@ng-web-apis/common";
import { EventsService } from "./app/shared/constants/events.service";
import { SetFilters } from "./app/store/user/user.actions";

declare function setIntercomForSignInUser(
  name: string,
  id: number,
  email: string,
  createdAt: number,
  userHash: string
): void;
declare function setIntercomForNotSignInUser(): void;

interface LocationHistory {
  previousUrl: string | null;
  currentUrl: string | null;
}

@Component({
  standalone: true,
  selector: "app-root",
  template: `
    <header>
      <div
        class="wrap-header"
        clickOutside
        (clickOutside)="hidePanelsOnOpenSearch = false"
      >
        <div class="section-left">
          <!-- <div class="icon-fa-bars" #menuBtn (click)="toggleLeftPanel()" [innerHTML]="IconBars | safe: SafeTypes.html"></div> -->
          <a
            href="/"
            [ngClass]="{ searchIsOpen: hidePanelsOnOpenSearch }"
            [innerHTML]="IconUventex | safe : SafeTypes.html"
            class="logo"
          ></a>
          <app-header
            [isDiffUrl]="isDiffUrl"
            [ngClass]="{ searchIsOpen: hidePanelsOnOpenSearch }"
          ></app-header>
          <app-search
            class="adaptive-only"
            [isDiffUrl]="isDiffUrl"
            [ngClass]="{ fullWidth: hidePanelsOnOpenSearch }"
            (displaySearch)="displaySearch($event)"
          ></app-search>
        </div>
        <div class="section-right">
          <div class="hub-management">
            <studio-local-selector
              [hidePanelsOnOpenSearch]="hidePanelsOnOpenSearch"
              [isDiffUrl]="isDiffUrl"
            ></studio-local-selector>
          </div>
        </div>
      </div>
    </header>
    <!-- 
    <div class="loader main" *ngIf="loaderService.isLoading$ | async;">
      <div class="loader-spinner">
        <img src="../../../../assets/img/loader.svg" alt="" />
      </div>
    </div> -->

    <div class="wrap-main">
      <div class="main">
        <!-- <studio-left-panel
                [open]="openLeftPanel$ | async"
                (close)="closeLeftPanel()"
                uiOutsideClick
                (outsideClick)="leftPanelClickOutside($event)"
                [lazySkipExcludes]="[mobileMenuButtom]"
        ></studio-left-panel> -->
        <div class="filter" *ngIf="true">
          <app-filter
            [title]="'SPORTS'"
            [maxItems]="8"
            [values]="topSports"
            [filteredItems]="filteredSports"
            [type] = FilterType.sports
            (filterItemSelected)="filterItemSelected($event, FilterType.sports)"
            (search)="onSearchChanged($event, FilterType.sports)"
          ></app-filter>
          <app-filter
            [title]="'LEAGUES'"
            [maxItems]="7"
            [values]="topLeagues"
            [filteredItems]="filteredLeagues"
            [type] = FilterType.leagues
            (filterItemSelected)="filterItemSelected($event, FilterType.leagues)"
            (search)="onSearchChanged($event, FilterType.leagues)"
          ></app-filter>
          <app-filter
            [title]="'COUNTRIES'"
            [maxItems]="4"
            [values]="topCountries"
            [filteredItems]="filteredCountries"
            [type] = FilterType.countries
            (filterItemSelected)="filterItemSelected($event, FilterType.countries)"
            (search)="onSearchChanged($event, FilterType.countries)"
          ></app-filter>
        </div>
        <div
          class="right-block"
          [ngClass]="{ 'full-width': !showFilter || isDiffUrl || fullWidth }"
        >
          <div class="outlet">
            <router-outlet></router-outlet>
            <ng-template #dialogComponentOutlet></ng-template>
            <ng-template #sidebarComponentOutletRight></ng-template>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  `,
  styleUrls: ["./main.scss"],
  imports: [
    AuthComponent,
    RouterModule,
    CommonModule,
    MatFormFieldModule,
    TranslocoModule,
    MatSelectModule,
    LeftPanelComponent,
    LocalizationModule,
    LocalSelectorComponent,
    ToastrModule,
    FooterComponent,
    SafePipe,
    OutsideClickDirective,
    UserSettingsComponent,
    HeaderComponent,
    FilterComponent,
    SearchComponent,
    MatButtonModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    ClickOutsideDirective,
    CarouselModule,
    ComingSoonComponent,
  ],
  providers: [TranslocoService, DialogService],
  animations: DialogAnimation,
})
export class AppComponent implements OnInit, AfterViewInit {
  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  public openLeftPanel$ = new BehaviorSubject<boolean>(false);
  public mobileMenuButtom = new BehaviorSubject<any>(null);
  public IconUventex = IconUventex;
  public IconBars = IconBars;
  public IconCalendar = IconCalendar;
  public IconLocation = IconLocation;
  public IconSearch = IconSearch;
  public SafeTypes = SafeTypes;
  public showFilter = false;
  public fullWidth = false;
  public lat!: number;
  public lng!: number;
  public moment = moment;
  public countries: any;
  /*public sportsList = [
    {name: "Sport Karate"},
    {name: "Traditional Karate"},
    {name: "MMA"},
    {name: "Taekwondo"},
    {name: "Judo"},
    {name: "Jiujitsu"},
    {name: "Kickboxing"},
    {name: "Tang Soo Do"},
  ];*/
  public router!: string;
  public hidePanelsOnOpenSearch!: boolean;
  public isDiffUrl!: boolean;

  @ViewChild("sidebarComponentOutletRight", {
    read: ViewContainerRef,
    static: true,
  })
  private sidebarComponentOutletRight!: ViewContainerRef;

  @ViewChild("dialogComponentOutlet", { read: ViewContainerRef, static: true })
  private dialogComponentOutlet!: ViewContainerRef;

  @HostBinding("@parentPopupWindow")
  public get isHasDialog(): boolean {
    return Boolean(
      this.dialogComponentOutlet && this.dialogComponentOutlet.length
    );
  }

  @ViewChild("menuBtn", { static: false })
  private set menuButton(menuButton: ElementRef<HTMLElement>) {
    if (menuButton) {
      this.mobileMenuButtom.next(menuButton.nativeElement);
    }
  }

  @ViewChild("picker") picker!: MatDateRangePicker<any>;
  @ViewChild("location") location!: ElementRef<HTMLElement>;
  @ViewChild("desktopSearchField")
  private desktopSearchField!: ElementRef<HTMLInputElement>;

  protected readonly FilterType = FilterType;

  private locationHistory: LocationHistory = {
    previousUrl: null,
    currentUrl: null,
  };

  public range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  public topSports: any[] = [];
  public topLeagues: any[] = [];
  public topCountries: any[] = [];
  public filteredSports: any[] = [];
  public filteredLeagues: any[] = [];
  public filteredCountries: any[] = [];

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private dictionariesContract: DictionariesContract,
    private sidebarService: SidebarService,
    private dialogService: DialogService,
    private _router: Router,
    private eventsService: EventsService,
    private store: Store,
  ) {
    _router.events.subscribe((r) => {
      if (r instanceof NavigationEnd) {
        if (
          r.url === "/meets" ||
          r.url === "/copy-past-even" ||
          r.url.indexOf("registration") >= 0
        ) {
          this.showFilter = false;
        } else {
          this.showFilter = true;
        }

        if (r.url.indexOf("dashboard") >= 0) {
          this.fullWidth = true;
        }
      }
    });

    this.authUser$.subscribe((user) => {
      if (user) {
        const now = new Date();
        const userId = user.studioAccount
          ? user.studioAccount.id
          : user.eventAccount
          ? user.eventAccount.id
          : 0;
        setIntercomForSignInUser(
          user.fullName,
          +user.intercomUserId,
          user.email,
          now.getTime(),
          user.intercomHash
        );
      } else {
        setIntercomForNotSignInUser();
      }
    });

    /*_router.events.subscribe((r) => {
      if (r instanceof NavigationEnd) {
        this.locationHistory.previousUrl = this.locationHistory.currentUrl;
        this.locationHistory.currentUrl = r.url;

        if (this.locationHistory.previousUrl && (this.locationHistory.previousUrl !== this.locationHistory.currentUrl)) {
          this.windowRef.location.reload();
        }
      }
    })*/
  }

  public ngOnInit(): void {
    this.getTopSportsList();
    this.getTopLeaguesList();
    this.getTopCountriesList();
  }

  public ngAfterViewInit(): void {
    this.sidebarService.setSidebarContainer(
      this.sidebarComponentOutletRight,
      "right"
    );
    this.dialogService.setDialogContainer(this.dialogComponentOutlet);

    this.dictionariesContract.getCountries().subscribe((countries) => {
      this.countries = countries.map((i: any) => i.name);
    });

    if (document.location.href.includes("diff")) this.isDiffUrl = true;
  }

  public toggleLeftPanel(): void {
    const next = !this.openLeftPanel$.value;
    this.openLeftPanel$.next(next);
  }

  public closeLeftPanel(): void {
    this.openLeftPanel$.next(false);
  }

  public leftPanelClickOutside(isOwn: any) {
    if (!isOwn) {
      this.closeLeftPanel();
    }
  }

  public displaySearch(event: boolean) {
    this.hidePanelsOnOpenSearch = event;
  }

  public displayMobilSearch(event: boolean) {
    console.log(event, "mobile");
  }

  public getCoords() {
    const successCallback = (position: any) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
    };

    const errorCallback = (error: any) => {
      console.log(error);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }

  public openPicker() {
    this.picker.open();
  }

  public search() {}

  public getTopSportsList() {
    this.eventsService.getTopSportsList().subscribe((sports: any) => {
      if (sports) {
        const topSports: any[] = [];
        sports.map((sport: any) => {
          topSports.push({
            id: sport.id,
            name: sport.name,
            url: sport.logo,
          });
        });

        this.topSports = topSports;
      }
    });
  }

  public getTopLeaguesList() {
    this.eventsService.getTopLeaguesList().subscribe((leagues: any) => {
      if (leagues) {
        const topLeagues: any[] = [];
        leagues.map((league: any) => {
          topLeagues.push({
            id: league.id,
            name: league.name,
            url: league.logoUrl,
          });
        });

        this.topLeagues = topLeagues;
      }
    });
  }

  public getTopCountriesList() {
    this.eventsService.getTopCountriesList().subscribe((countries: any) => {
      if (countries) {
        const topCountries: any[] = [];
        countries.map((country: any) => {
          topCountries.push({
            id: country.id,
            name: country.name,
            code: country.code,
            url: country.code ? `https://cdn.uventex.com/events/images/flags/${country.code}.png` : null,
          });
        });

        this.topCountries = topCountries;
      }
    });
  }

  filterItemSelected(item: any, type: FilterType) {
    const filters = this.store.selectSnapshot(UserState.filters);

    const newFilters = {
      sportIds: (filters ? filters.sportIds : []),
      leagueIds: (filters ? filters.leagueIds : []),
      countryIds : (filters ? filters.countryIds : []),
    };

    if (type === FilterType.sports) {
      newFilters.sportIds = item;
    } else if (type === FilterType.leagues) {
      newFilters.leagueIds = item;
    } else if (type === FilterType.countries) {
      newFilters.countryIds = item;
    }

    this.store.dispatch(new SetFilters(newFilters));
  }

  onSearchChanged(search: string, type: FilterType) {
    const clearFilter = !(search.length > 0);

    if (type === FilterType.sports) {
      if (clearFilter) {
        this.filteredSports = [];
      } else {
        this.eventsService.getFilteredSportsList(search).subscribe((response: any) => {
          const sports: any[] = [];
          if (response?.items) {
            const items = response.items;

            items.map((sport: any) => {
              sports.push({
                id: sport.id,
                name: sport.name,
                url: sport.logo,
              });
            });
          }

          this.filteredSports = sports;
        });
      }
    } else if (type === FilterType.leagues) {
      if (clearFilter) {
        this.filteredLeagues = [];
      } else {
        this.eventsService.getFilteredLeaguesList(search).subscribe((response: any) => {
          const leagues: any[] = [];
          if (response?.items) {
            const items = response.items;

            items.map((league: any) => {
              leagues.push({
                id: league.id,
                name: league.name,
                url: league.logoUrl,
              });
            });
          }

          this.filteredLeagues = leagues;
        });
      }
    } else if (type === FilterType.countries) {
      if (clearFilter) {
        this.filteredCountries = [];
      } else {
        this.eventsService.getFilteredCountriesList(search).subscribe((response: any) => {
          const countries: any[] = [];
          if (response?.items) {
            const items = response.items;

            items.map((country: any) => {
              countries.push({
                id: country.id,
                name: country.name,
                code: country.code,
                url: country.code ? `https://cdn.uventex.com/events/images/flags/${country.code}.png` : null,
              });
            });
          }

          this.filteredCountries = countries;
        });
      }
    }
  }

}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideAnimations(),
    ProductsGuard,
    Store,
    provideHttpClient(
      withInterceptors([
        (req, next) => {
          return next(req);
        },
      ]),
      withInterceptorsFromDi()
    ),
    importProvidersFrom(
      ToastrModule.forRoot({
        preventDuplicates: true,
        autoDismiss: true,
      }),
      NgxsModule.forRoot(StoreList, {
        developmentMode: !environment.production,
      })
    ),
    provideRouter([
      {
        path: "store/list",
        loadComponent: () =>
          import(
            "src/app/pages/store/products-list/products-list.component"
          ).then((m) => m.ProductsListComponent),
        providers: [
          //Store,
          StateStream,
          importProvidersFrom(NgxsModule.forFeature([OnlineStoreState])),
        ],
        canActivate: [ProductsGuard],
      },
      {
        path: "store/product/:studioId/:categoryId",
        loadComponent: () =>
          import("src/app/pages/store/product/product.component").then(
            (m) => m.UvProductComponent
          ),
        resolve: { data: ProductResolver },
        providers: [
          //Store,
          StateStream,
          ProductResolver,
          importProvidersFrom(NgxsModule.forFeature([OnlineStoreState])),
        ],
      },
      {
        path: "store/cart",
        loadComponent: () =>
          import("src/app/pages/cart/cart.component").then(
            (m) => m.UvCartComponent
          ),
      },
      {
        path: "auth-jws/token/:token",
        loadComponent: () =>
          import("src/app/pages/auth/auth.component").then(
            (m) => m.AuthComponent
          ),
        resolve: { data: AuthTokenResolver },
        providers: [AuthTokenResolver],
      },
      {
        path: "events",
        loadComponent: () =>
          import("src/app/pages/home/events/events.component").then(
            (m) => m.EventsComponent
          ),
      },
      {
        path: "events/event/:eventId",
        loadComponent: () =>
          import("src/app/pages/home/event/event.component").then(
            (m) => m.EventComponent
          ),
      },
      {
        path: "hub-studios",
        loadComponent: () =>
          import("src/app/pages/home/hub-studios/hub-studios.component").then(
            (m) => m.HubStudiosComponent
          ),
      },
      {
        path: "hub-studios/studio/:studioId",
        loadComponent: () =>
          import("src/app/pages/home/studio/studio.component").then(
            (m) => m.StudioComponent
          ),
      },
      {
        path: "hub-studios/studio/:studioId/diff",
        loadComponent: () =>
          import("src/app/pages/home/studio/studio.component").then(
            (m) => m.StudioComponent
          ),
      },
      // {
      //   path: 'tv',
      //   loadComponent: () => import('src/app/pages/home/tv/tv.component').then(m => m.TvComponent),
      // },
      {
        path: "athletes",
        loadComponent: () =>
          import("src/app/pages/athletes/athletes.component").then(
            (m) => m.AthletesComponent
          ),
      },
      {
        path: "athletes/:id",
        loadComponent: () =>
          import("src/app/pages/athlete/athlete.component").then(
            (m) => m.AthleteComponent
          ),
      },
      {
        path: "leagues",
        loadComponent: () =>
          import("src/app/pages/leagues/leagues.component").then(
            (m) => m.LeaguesComponent
          ),
      },
      {
        path: "news",
        loadComponent: () =>
          import("src/app/pages/news/news.component").then(
            (m) => m.NewsComponent
          ),
      },
      {
        path: "meets",
        loadComponent: () =>
          import("src/app/pages/meet-page/meet-page.component").then(
            (m) => m.MeetPageComponent
          ),
      },
      {
        path: "create-event",
        loadComponent: () =>
          import("src/app/pages/create-event/create-event.component").then(
            (m) => m.CreateEventComponent
          ),
      },
      {
        path: "create-new-event",
        loadComponent: () =>
          import(
            "src/app/pages/create-event/new-event/new-event.component"
          ).then((m) => m.NewEventComponent),
      },
      {
        path: "create-event/new-event/:eventId",
        loadComponent: () =>
          import(
            "src/app/pages/create-event/new-event/new-event.component"
          ).then((m) => m.NewEventComponent),
      },
      {
        path: "registration/:eventId",
        loadComponent: () =>
          import("src/app/pages/registration/registration.component").then(
            (m) => m.RegistrationComponent
          ),
        canActivate: [EventRegistrationGuard],
      },
      {
        path: "dashboard",
        loadComponent: () =>
          import("src/app/pages/dashboard/dashboard.component").then(
            (m) => m.DashboardComponent
          ),
      },
      {
        path: "judge-assignment",
        loadComponent: () =>
          import("src/app/pages/judge/judge-assignment.component").then(
            (m) => m.JudgeAssignmentComponent
          ),
      },
      {
        path: "",
        loadComponent: () =>
          import("src/app/pages/home/home.component").then(
            (m) => m.HomeComponent
          ),
        children: [],
      },
    ]),
  ],
});
