import { Component, OnInit, HostBinding } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DialogThemes } from 'src/app/shared/constants/dialog-themes.enum';
import { DialogModel } from 'src/app/shared/models/dialog.model';
import {CommonModule} from "@angular/common";
import {LocalizationModule} from "../localization";

@Component({
  standalone: true,
  selector: 'studio-dialog-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss'],
  imports: [
    CommonModule,
    LocalizationModule
  ]
})
export class BasicDialogComponent implements OnInit {
  public dialog!: DialogModel;

  public dialogResult = new EventEmitter<boolean | void>();

  public DialogThemes = DialogThemes;

  @HostBinding('attr.theme')
  public get theme(): DialogThemes | null {
    return this.dialog.theme || null;
  }

  public ngOnInit(): void {
    if (this.dialog.closeTimeout) {
      setTimeout(() => this.onOk(), this.dialog.closeTimeout);
    }
  }

  public isConfirmTheme(theme?: DialogThemes): boolean {
    if (!theme) {
      return false;
    }

    return DialogThemes.Confirm === theme;
  }

  public onOk(): void {
    this.dialogResult.emit();
  }

  public onYes(): void {
    this.dialogResult.emit(true);
  }

  public onNo(): void {
    this.dialogResult.emit(false);
  }
}
