export const IconCreateNewEvent = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
<g clip-path="url(#clip0_2445_6215)">
  <path d="M177.083 25H81.2497C68.583 25 58.333 35.25 58.333 47.9167V96.3333L59.6663 97.25C61.1663 98.4167 62.4163 99.75 63.583 101.167C70.9163 102.25 77.583 103.833 83.9163 105.833C84.8247 103.667 87.083 102.083 89.583 102.083H168.75C172.166 102.083 175 104.917 175 108.333C175 111.75 172.166 114.583 168.75 114.583H110.583C121.5 116.5 135.083 120.25 143.083 129.167H168.75C170.166 129.167 171.5 129.658 172.583 130.5C176.333 129.658 179.916 129.167 183.333 129.167C189.5 129.167 195.166 130.5 200 132.833V47.9167C200 35.25 189.741 25 177.083 25ZM168.75 87.5H89.583C86.1663 87.5 83.333 84.6667 83.333 81.25C83.333 77.8333 86.1663 75 89.583 75H168.75C172.166 75 175 77.8333 175 81.25C175 84.6667 172.166 87.5 168.75 87.5Z"/>
  <path d="M160.417 16.6667H151.25C148.5 7.08333 139.659 0 129.167 0C118.667 0 109.834 7.08333 107.084 16.6667H97.917C94.5003 16.6667 91.667 19.5 91.667 22.9167V39.5833C91.667 47.5833 98.2503 54.1667 106.25 54.1667H152.084C160.084 54.1667 166.667 47.5833 166.667 39.5833V22.9167C166.667 19.5 163.834 16.6667 160.417 16.6667Z"/>
  <path d="M175 81.25C175 84.6667 172.166 87.5 168.75 87.5H89.583C86.1663 87.5 83.333 84.6667 83.333 81.25C83.333 77.8333 86.1663 75 89.583 75H168.75C172.166 75 175 77.8333 175 81.25Z" fill="white"/>
  <path d="M175 108.333C175 111.75 172.166 114.583 168.75 114.583H110.583C107.258 114 104.25 113.5 101.675 113.167L101.341 113.083C95.7581 110.158 90.0081 107.742 83.9248 105.833C84.8331 103.667 87.0915 102.083 89.5915 102.083H168.758C172.166 102.083 175 104.917 175 108.333Z" fill="white"/>
  <path d="M172.583 130.5C165.083 132.25 157.083 135.417 148.666 138.833C147.5 135.083 145.5 131.917 143.083 129.167H168.75C170.166 129.167 171.5 129.667 172.583 130.5Z" fill="white"/>
  <path d="M129.458 114.583H110.583C116.533 115.633 123.266 117.217 129.466 119.883V114.583H129.458ZM91.6663 25H81.2497C68.583 25 58.333 35.25 58.333 47.9167V96.3333L59.6663 97.25C61.1663 98.4167 62.4163 99.75 63.583 101.167C70.9163 102.25 77.583 103.833 83.9163 105.833C84.8247 103.667 87.083 102.083 89.583 102.083H129.458V87.5H89.583C86.1663 87.5 83.333 84.6667 83.333 81.25C83.333 77.8333 86.1663 75 89.583 75H129.458V54.1667H106.25C98.2497 54.1667 91.6663 47.5833 91.6663 39.5833V25Z"/>
  <path d="M129.167 0C118.667 0 109.834 7.08333 107.084 16.6667H97.917C94.5003 16.6667 91.667 19.5 91.667 22.9167V25V39.5833C91.667 47.5833 98.2503 54.1667 106.25 54.1667H129.459V0C129.359 0 129.267 0 129.167 0Z" />
  <path d="M129.458 75H89.583C86.1663 75 83.333 77.8333 83.333 81.25C83.333 84.6667 86.1663 87.5 89.583 87.5H129.458V75Z" fill="white"/>
  <path d="M129.459 102.083H89.5837C87.0837 102.083 84.8337 103.667 83.917 105.833C89.992 107.75 95.7503 110.167 101.334 113.083L101.667 113.167C104.25 113.5 107.25 114 110.575 114.583H129.459V102.083Z" fill="white"/>
  <path d="M183.333 145.833C166.667 145.833 133.333 166.925 116.667 166.925C100 166.925 81.2501 154.167 81.2501 154.167C81.2501 154.167 103.692 158.333 116.667 158.333C129.642 158.333 133.333 153.908 133.333 147.917C133.333 133.333 108.558 131.058 96.0335 129.158C83.7251 122.317 70.7251 118.058 50.3501 116.333C50.2251 117.517 50.0418 118.7 49.7085 119.883L32.9668 178.883C51.9335 187.5 91.1918 200 108.333 200C133.333 200 200 166.667 200 158.333C200 150 191.667 145.833 183.333 145.833Z"/>
  <path d="M29.1667 183.333H6.25C2.8 183.333 0 180.533 0 177.083V106.25C0 102.8 2.8 100 6.25 100C35.4583 100 49.0667 110.142 49.625 110.567C51.6417 112.117 52.5333 114.717 51.875 117.175L35.2083 178.717C34.4667 181.442 31.9917 183.333 29.1667 183.333Z"/>
  <path d="M133.05 145.317H44.2501L35.2085 178.717C35.1168 179.067 34.9918 179.4 34.8418 179.717C54.4085 188.283 91.7418 200 108.333 200C133.333 200 200 166.667 200 158.333C200 150 191.667 145.833 183.333 145.833C166.667 145.833 133.333 166.925 116.667 166.925C100 166.925 81.2501 154.167 81.2501 154.167C81.2501 154.167 103.692 158.333 116.667 158.333C129.642 158.333 133.333 153.908 133.333 147.917C133.333 147 133.233 146.133 133.05 145.317Z"/>
  <path d="M44.25 145.317H0V177.083C0 180.533 2.8 183.333 6.25 183.333H29.1667C31.6333 183.333 33.825 181.9 34.8417 179.717C34.9917 179.4 35.1083 179.067 35.2083 178.717L44.25 145.317Z"/>
</g>
<defs>
  <clipPath id="clip0_2445_6215">
    <rect width="200" height="200" fill="white"/>
  </clipPath>
</defs>
</svg>`