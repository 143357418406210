export const IconEvents = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
<g clip-path="url(#clip0_97_1333)">
  <path d="M7.51562 0.875H12.3906C12.7272 0.875 13 1.16881 13 1.53125V5.90625C13 6.26869 12.7272 6.5625 12.3906 6.5625H7.51562C7.17907 6.5625 6.90625 6.26869 6.90625 5.90625V1.53125C6.90625 1.16881 7.17907 0.875 7.51562 0.875ZM5.48438 0.875H0.609375C0.272822 0.875 0 1.16881 0 1.53125V5.90625C0 6.26869 0.272822 6.5625 0.609375 6.5625H5.48438C5.82093 6.5625 6.09375 6.26869 6.09375 5.90625V1.53125C6.09375 1.16881 5.82093 0.875 5.48438 0.875ZM0 8.09375V12.4688C0 12.8312 0.272822 13.125 0.609375 13.125H5.48438C5.82093 13.125 6.09375 12.8312 6.09375 12.4688V8.09375C6.09375 7.73131 5.82093 7.4375 5.48438 7.4375H0.609375C0.272822 7.4375 0 7.73131 0 8.09375ZM7.51562 13.125H12.3906C12.7272 13.125 13 12.8312 13 12.4688V8.09375C13 7.73131 12.7272 7.4375 12.3906 7.4375H7.51562C7.17907 7.4375 6.90625 7.73131 6.90625 8.09375V12.4688C6.90625 12.8312 7.17907 13.125 7.51562 13.125Z"/>
</g>
<defs>
  <clipPath id="clip0_97_1333">
    <rect width="13" height="14" fill="white"/>
  </clipPath>
</defs>
</svg>`;
