<div *ngIf="!registrationComplete">
  <form [formGroup]="signupForm" #registerForm="ngForm" (ngSubmit)="onFormSubmit()">
    <div class="signup-form-container">
      <div class="fields-container">
        <div class="field-item">
          <mat-form-field>
            <mat-label>{{ 'FIRST_NAME' | transloco }}</mat-label>
            <input
              matInput
              required
              type="text"
              [placeholder]="'FIRST_NAME' | transloco"
              [formControl]="firstNameControl"
            />
          </mat-form-field>
          <mat-error class="form-top-margin" *ngIf="registerForm.submitted && firstNameControl.invalid">{{ 'FIRST_NAME_NOT_CORRECT' | transloco }}</mat-error>
        </div>
        <div class="field-item form-top-margin">
          <mat-form-field>
            <mat-label>{{ 'LAST_NAME' | transloco }}</mat-label>
            <input
              matInput
              required
              type="text"
              [placeholder]="'LAST_NAME' | transloco"
              [formControl]="lastNameControl"
            />
          </mat-form-field>
          <mat-error class="form-top-margin" *ngIf="registerForm.submitted && lastNameControl.invalid">{{ 'LAST_NAME_NOT_CORRECT' | transloco }}</mat-error>
        </div>
        <div class="field-item form-top-margin">
          <mat-form-field>
            <mat-label>{{ 'EMAIL' | transloco }}</mat-label>
            <input
              matInput
              required
              email
              type="email"
              [placeholder]="'EMAIL' | transloco"
              [formControl]="usernameControl"
            />
          </mat-form-field>
          <mat-error class="form-top-margin" *ngIf="registerForm.submitted && usernameControl.invalid">{{ 'EMAIL_NOT_CORRECT' | transloco }}</mat-error>
        </div>
        <div class="field-item form-top-margin">
          <mat-form-field>
            <mat-label>{{ 'PASSWORD' | transloco }}</mat-label>
            <input
              matInput
              required
              [type]="hidePassword ? 'password' : 'text'"
              [placeholder]="'PASSWORD' | transloco"
              [formControl]="passwordControl"
            />
            <mat-icon matSuffix class="hide-password-button" (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </mat-form-field>
          <mat-error class="form-top-margin" *ngIf="registerForm.submitted && passwordControl.invalid">{{ 'ENTER_A_VALID_PASSWORD' | transloco }}</mat-error>
        </div>
      </div>

      <div class="checkbox-fields">
        <mat-checkbox class="checkbox-margin" [formControl]="policyControl">
          <div style="margin-top: 21px">
            <div class="checkbox-field-font">
              {{ 'READ_AND_AGREE' | transloco}}
            </div>
            <div class="checkbox-field-font">
              <a (click)="openPrivacyPolicyLink()" class="privacy-policy-link">{{ 'PRIVACY_POLICY' | transloco}}</a>
            </div>
          </div>
        </mat-checkbox>

        <mat-checkbox class="checkbox-margin" [formControl]="termsOfServiceControl">
          <div style="margin-top: 21px">
            <div class="checkbox-field-font">
              {{ 'READ_AND_AGREE' | transloco}}
            </div>
            <div class="checkbox-field-font">
              <a (click)="openPrivacyPolicyLink()" class="privacy-policy-link">{{ 'TERMS_OF_SERVICE' | transloco}}</a>
            </div>
          </div>
        </mat-checkbox>
      </div>

      <div class="bottom-container" style="margin-top: 30px">
        <div class="field-item">
          <button class="btn-7 w-100 signup-button" type="submit" [disabled]="!signupForm.valid || isLoading">{{ 'SIGN_UP' | transloco }}</button>
        </div>
        <div class="signin-container">
          <div class="field-row text-font">
            <div>{{ 'HAVE_AN_ACCOUNT' | transloco }}</div>
            <a class="signin-link" (click)="displaySignInForm()">{{ 'SIGN_IN' | transloco }}</a>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="register-success-container" *ngIf="registrationComplete">
  <div class="registration-success-checkmark">
    <div class="checkmark"></div>
  </div>

  <div class="success-title">{{ 'REGISTRATION_SUCCESS_TITLE' | transloco }}</div>
  <div class="success-subtitle">{{ 'THANK_YOU_JOINING_UVENTEX_SPORTS_HUB' | transloco }}</div>
</div>
