export const IconInstagram = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<g clip-path="url(#clip0_2087_422)">
  <path d="M14.2988 12C14.2988 13.2703 13.2723 14.2969 12.002 14.2969C10.7316 14.2969 9.70508 13.2703 9.70508 12C9.70508 10.7297 10.7316 9.70312 12.002 9.70312C13.2723 9.70312 14.2988 10.7297 14.2988 12Z" fill="white"/>
  <path d="M17.3746 7.93672C17.2621 7.63672 17.0887 7.36484 16.859 7.14453C16.6387 6.91484 16.3668 6.74141 16.0668 6.62891C15.823 6.53516 15.4574 6.42266 14.7871 6.38984C14.0605 6.35703 13.8402 6.34766 12.0027 6.34766C10.1605 6.34766 9.94492 6.35234 9.21836 6.38984C8.54805 6.42266 8.18242 6.53516 7.93867 6.62891C7.63867 6.74141 7.3668 6.91484 7.14648 7.14453C6.9168 7.36484 6.74336 7.63672 6.63086 7.93672C6.53711 8.18047 6.42461 8.54609 6.3918 9.21641C6.35898 9.94297 6.34961 10.1586 6.34961 12.0008C6.34961 13.8383 6.35898 14.0586 6.3918 14.7852C6.42461 15.4555 6.53242 15.8211 6.63086 16.0648C6.74336 16.3648 6.9168 16.6367 7.14648 16.857C7.3668 17.0867 7.63867 17.2602 7.93867 17.3727C8.18242 17.4664 8.54805 17.5789 9.21836 17.6117C9.94492 17.6445 10.1605 17.6539 12.0027 17.6539C13.8449 17.6539 14.0605 17.6492 14.7871 17.6117C15.4574 17.5789 15.823 17.4711 16.0668 17.3727C16.6668 17.143 17.1449 16.6648 17.3746 16.0648C17.4684 15.8211 17.5809 15.4555 17.6137 14.7852C17.6465 14.0586 17.6559 13.8383 17.6559 12.0008C17.6559 10.1586 17.6512 9.94297 17.6137 9.21641C17.5809 8.54609 17.4684 8.18047 17.3746 7.93672ZM12.0027 15.5398C10.048 15.5398 8.46367 13.9555 8.46367 12.0008C8.46367 10.0461 10.048 8.46172 12.0027 8.46172C13.9574 8.46172 15.5418 10.0461 15.5418 12.0008C15.5418 13.9555 13.9574 15.5398 12.0027 15.5398ZM15.6824 9.15078C15.2277 9.15078 14.8574 8.78047 14.8574 8.32578C14.8574 7.87109 15.2277 7.50078 15.6824 7.50078C16.1371 7.50078 16.5074 7.87109 16.5074 8.32578C16.5074 8.78047 16.1371 9.15078 15.6824 9.15078Z" fill="white"/>
  <path d="M12.002 0C5.37383 0 0.00195312 5.37188 0.00195312 12C0.00195312 18.6281 5.37383 24 12.002 24C18.6301 24 24.002 18.6281 24.002 12C24.002 5.37188 18.6301 0 12.002 0ZM18.8504 14.8406C18.8176 15.5719 18.7004 16.0734 18.5316 16.5141C18.1754 17.4375 17.4441 18.1734 16.516 18.5297C16.0801 18.6984 15.5785 18.8156 14.8426 18.8484C14.1066 18.8813 13.8723 18.8906 12.002 18.8906C10.1316 18.8906 9.89727 18.8813 9.16133 18.8484C8.43008 18.8156 7.92852 18.6984 7.48789 18.5297C7.02852 18.3562 6.61133 18.0844 6.26914 17.7328C5.91758 17.3906 5.6457 16.9734 5.47227 16.5141C5.30352 16.0781 5.18633 15.5766 5.15352 14.8406C5.1207 14.1047 5.11133 13.8703 5.11133 12C5.11133 10.1297 5.1207 9.89531 5.15352 9.15937C5.18633 8.42812 5.30352 7.92656 5.47227 7.48594C5.6457 7.02656 5.91758 6.60938 6.26914 6.26719C6.61133 5.91562 7.02852 5.64375 7.48789 5.47031C7.92852 5.30156 8.42539 5.18438 9.16133 5.15156C9.89727 5.11875 10.1316 5.10938 12.002 5.10938C13.8723 5.10938 14.1066 5.11875 14.8426 5.15156C15.5738 5.18438 16.0754 5.30156 16.516 5.47031C16.9754 5.64375 17.3926 5.91562 17.7348 6.26719C18.0863 6.60938 18.3582 7.02656 18.5316 7.48594C18.7004 7.92656 18.8176 8.42344 18.8504 9.15937C18.8832 9.89531 18.8926 10.1297 18.8926 12C18.8926 13.8703 18.8832 14.1047 18.8504 14.8406Z" fill="white"/>
</g>
<defs>
  <clipPath id="clip0_2087_422">
    <rect width="24" height="24" fill="white" transform="translate(0.00195312)"/>
  </clipPath>
</defs>
</svg>`