import { PublicStoreStudio } from '../../../shared/models/studio.model';
import { ProductFilter } from '../../../shared/models/filter-form.model';

export class SetProductFilter {
  static readonly type = '[Online Store] Set Product Filter';
  constructor(public productFilter: ProductFilter) {}
}

export class SetStudios {
  static readonly type = '[Online Store] Set Studios';
  constructor(public studios: PublicStoreStudio[]) {}
}
