import { Injectable } from '@angular/core';
import { State, StateContext, NgxsOnInit, Select, Selector } from '@ngxs/store';
import { Currency } from 'src/app/shared/models/currency.model';
import { DictionariesContract } from '../../shared/contracts/dictionaries.contract';

interface Dictionaries {
  currencies: Currency[];
}

const defaultState = (): Dictionaries => ({ currencies: [] });

@State<Dictionaries>({
  name: 'dictionaries',
  defaults: defaultState(),
})
@Injectable()
export class DictionariesState implements NgxsOnInit {
  @Selector()
  static currencies(state: Dictionaries): Currency[] {
    return state.currencies;
  }

  constructor(private dictionariesController: DictionariesContract) {}

  public ngxsOnInit({ setState }: StateContext<Dictionaries>): void {
    this.dictionariesController.getCurrencies().subscribe((currencies) => {
      setState({ currencies });
    });
  }
}
