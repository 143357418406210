import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { GlobalNotificationModel } from 'src/app/shared/models/global-notifications.model';
import { filter, first } from 'rxjs/operators';

import { ToastComponent } from '../../components/toast/toast.component';

@Injectable()
export class ToastService {
  private notifyComponentRef: ComponentRef<ToastComponent> | null = null;

  private renderer!: Renderer2;

  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public show(notify: GlobalNotificationModel): void {
    if (!this.notifyComponentRef) {
      this.createToastComponent(notify);
      return;
    }

    if (!notify) {
      this.clearToast();
      return;
    }

    this.notifyComponentRef.instance.notification = notify;
  }

  private createToastComponent(notify: GlobalNotificationModel): void {
    const factory = this.resolver.resolveComponentFactory(ToastComponent);
    this.notifyComponentRef = factory.create(this.injector);

    this.notifyComponentRef.instance.notification = notify;

    this.renderer.appendChild(this.document.body, this.notifyComponentRef.location.nativeElement);
    this.applicationRef.attachView(this.notifyComponentRef.hostView);

    this.subscribeToRouterEvents();
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationStart),
        first(),
      )
      .subscribe(() => {
        this.clearToast();
      });
  }

  private clearToast(): void {
    if (!this.notifyComponentRef) {
      return;
    }

    this.applicationRef.detachView(this.notifyComponentRef.hostView);
    this.notifyComponentRef.destroy();
    this.notifyComponentRef = null;
  }
}
