<div class="filter-section">
    <div class="filter-title">
        <span>{{ title | transloco }}</span>
        <button class="clear-filters-btn" (click)="clearFilters()">
            <span>{{ 'CLEAR_FILTERS' | transloco }}</span>
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6484 5.92188L9.32031 9.25H12.7188C12.8594 9.25 13 9.39062 13 9.53125V10.4688C13 10.6328 12.8594 10.75 12.7188 10.75H4.375C4.04688 10.75 3.76562 10.6328 3.57812 10.4219L1.32812 8.17188C0.882812 7.75 0.882812 7.02344 1.32812 6.60156L7.32812 0.601562C7.75 0.15625 8.47656 0.15625 8.89844 0.601562L12.6484 4.35156C13.0938 4.77344 13.0938 5.5 12.6484 5.92188ZM5.57031 4.46875L2.64062 7.375L4.51562 9.25H7.21094L8.78125 7.67969L5.57031 4.46875Z" fill="#E82B36"/>
            </svg>
        </button>
    </div>
    <mat-form-field class="filter-input">
        <mat-label>Search</mat-label>
        <!--<input type="tel" matInput [(ngModel)]="searchText" (ngModelChange)="onSearchChange($event)">-->
        <input type="text" matInput #searchInput="matInput" [(ngModel)]="searchText" (ngModelChange)="onSearchChange($event)">
        <mat-icon [color]="searchInput.focused ? 'primary' : ''" matPrefix>search</mat-icon>
    </mat-form-field>
    <ul class="filter-list">
        <!--<li class="filter-item" *ngFor="let c of values | slice:0:8">-->
        <li class="filter-item" *ngFor="let item of items" (click)="selectItem(item)">
            <ng-container *ngIf="type === FilterType.leagues">
                <img *ngIf="item.url" [src]="item.url" alt="{{ item.name }}" class="league-icon"/>
                <div *ngIf="!item.url" class="league-icon"></div>
            </ng-container>

            <ng-container *ngIf="type === FilterType.countries">
                <img *ngIf="item.url" [src]="item.url" alt="{{ item.name }}" class="country-flag"/>
                <div *ngIf="!item.url" class="country-flag"></div>
            </ng-container>

            <div class="item-title">
                <span [ngClass]="{'selected-item': isItemSelected(item)}">{{ item.name }}</span>
                <button *ngIf="isItemSelected(item)" class="remove-selection-btn" (click)="deselectItem(item); $event.stopPropagation();">
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.61719 5.75L10.3516 8.51172C10.707 8.83984 10.707 9.38672 10.3516 9.71484L9.75 10.3164C9.42188 10.6719 8.875 10.6719 8.54688 10.3164L5.8125 7.58203L3.05078 10.3164C2.72266 10.6719 2.17578 10.6719 1.84766 10.3164L1.24609 9.71484C0.890625 9.38672 0.890625 8.83984 1.24609 8.51172L3.98047 5.75L1.24609 3.01562C0.890625 2.6875 0.890625 2.14062 1.24609 1.8125L1.84766 1.21094C2.17578 0.855469 2.72266 0.855469 3.05078 1.21094L5.8125 3.94531L8.54688 1.21094C8.875 0.855469 9.42188 0.855469 9.75 1.21094L10.3516 1.8125C10.707 2.14062 10.707 2.6875 10.3516 3.01562L7.61719 5.75Z" fill="#01A1DA"/>
                    </svg>
                </button>
            </div>
        </li>
        <li class="filter-item" *ngFor="let item of _filteredItems" (click)="selectItem(item)">
            <ng-container *ngIf="type === FilterType.leagues">
                <img *ngIf="item.url" [src]="item.url" alt="{{ item.name }}" class="league-icon"/>
                <div *ngIf="!item.url" class="league-icon"></div>
            </ng-container>

            <ng-container *ngIf="type === FilterType.countries">
                <img *ngIf="item.url" [src]="item.url" alt="{{ item.name }}" class="country-flag"/>
                <div *ngIf="!item.url" class="country-flag"></div>
            </ng-container>

            <span [ngClass]="{'selected-item': isItemSelected(item)}">{{ item.name }}</span>
        </li>
    </ul>
</div>