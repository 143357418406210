import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapchaService } from "../../../shared/services/captcha.service";
import { TranslocoService } from '@ngneat/transloco';
import { LocalizationModule } from "../../localization";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { emailPattern, passwordPattern } from "../../../../app/utils/control-utils";
import { AuthContract } from "../../../shared/contracts/auth.contract";

const TIMER_INTERVAL = 60;

export enum ForgotPasswordSteps {
  step1 = 'step1',
  step2 = 'step2',
  step3 = 'step3',
  step4 = 'step4',
}

@Component({
  standalone: true,
  selector: 'events-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  imports: [
    LocalizationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  providers: [TranslocoService],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  @Output() displaySignIn = new EventEmitter();

  public isLoading = false;

  hidePassword = true;
  hideConfirmPassword = true;
  registrationComplete = false;

  public usernameControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(emailPattern)]);
  public passwordControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(passwordPattern)]);
  public confirmPasswordControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(passwordPattern)]);

  public usernameFormGroup = new FormGroup({
    username: this.usernameControl,
  });

  public passwordFormGroup = new FormGroup({
    password: this.passwordControl,
    confirmPassword: this.confirmPasswordControl,
  });

  public ForgotPasswordSteps = ForgotPasswordSteps;
  public activeStep$ : ForgotPasswordSteps = ForgotPasswordSteps.step1;

  timerCounter: number = 0;
  timerId: number = 0;
  timerIsOver: boolean = false;
  timerStr: string = '';

  constructor(
    private authContract: AuthContract,
    private toastr: ToastrService,
    private transloco: TranslocoService,
    private capchaService: CapchaService,
  ) {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  public onUsernameFormSubmit() {
    if (!this.usernameFormGroup.valid) {
      return;
    }

    this.setStep(ForgotPasswordSteps.step2);
  }

  public onUpdatePasswordFormSubmit() {
    if (!this.passwordFormGroup.valid) {
      return;
    }

    this.setStep(ForgotPasswordSteps.step4);
  }

  displaySignInForm() {
    this.stopTimer();

    this.displaySignIn.emit();
  }

  setStep(step: ForgotPasswordSteps) {
    this.activeStep$ = step;

    if (step === ForgotPasswordSteps.step2) {
      this.prepareTimer();
      this.timerId = setInterval(() => this.setSendEmailTimer(), 1000);
    }
  }

  get username() {
    return this.usernameControl.value;
  }

  prepareTimer() {
    if (this.timerId > 0) {
      clearInterval(this.timerId);
    }

    this.timerCounter = TIMER_INTERVAL;
    this.timerIsOver = false;

    this.timerStr = ' ' + this.transloco.translate('TRY_AGAIN_IN') + ' ' + this.timerCounter + ' ' + this.transloco.translate('SECONDS');
  }

  setSendEmailTimer() {
    if (this.timerCounter > 0) {
      this.timerCounter--;
    }

    this.timerStr = ' ';
    if (this.timerCounter > 0) {
      this.timerStr += this.transloco.translate('TRY_AGAIN_IN') + ' ' + this.timerCounter + ' ' + this.transloco.translate('SECONDS');
    } else {
      clearInterval(this.timerId);
      this.timerIsOver = true;
    }
  }

  stopTimer() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

}