export const IconTwitter = `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12.002" cy="12" r="12" fill="white"/>
<g clip-path="url(#clip0_2087_430)">
<path d="M10.4612 16.9691C14.6122 16.9691 16.8826 13.53 16.8826 10.5477C16.8826 10.45 16.8826 10.3528 16.876 10.256C17.3177 9.93652 17.6989 9.54095 18.002 9.0878C17.5901 9.2704 17.1531 9.39009 16.7057 9.44288C17.1769 9.16086 17.5295 8.71724 17.6979 8.1946C17.2549 8.4575 16.7702 8.64277 16.2648 8.7424C15.9246 8.38058 15.4745 8.14099 14.9844 8.06071C14.4942 7.98043 13.9913 8.06393 13.5534 8.2983C13.1154 8.53266 12.767 8.90482 12.5619 9.35718C12.3568 9.80955 12.3065 10.3169 12.4188 10.8007C11.5215 10.7557 10.6438 10.5225 9.84245 10.1163C9.04115 9.71008 8.33422 9.13989 7.76755 8.44276C7.47895 8.93959 7.39056 9.52774 7.52037 10.0875C7.65019 10.6472 7.98844 11.1364 8.46627 11.4554C8.1071 11.4449 7.75575 11.348 7.44195 11.173V11.2016C7.4421 11.7226 7.62247 12.2276 7.95248 12.6308C8.28249 13.034 8.74181 13.3107 9.25255 13.4139C8.9203 13.5045 8.57169 13.5178 8.23351 13.4526C8.37778 13.901 8.65857 14.2931 9.0366 14.5741C9.41464 14.8551 9.87104 15.011 10.342 15.0199C9.87407 15.3877 9.33822 15.6596 8.7651 15.8201C8.19199 15.9806 7.59284 16.0266 7.00195 15.9553C8.03404 16.6176 9.23492 16.9689 10.4612 16.9673" fill="#1B1B1B"/>
</g>
<defs>
<clipPath id="clip0_2087_430">
<rect width="11" height="11" fill="white" transform="translate(7.00195 7)"/>
</clipPath>
</defs>
</svg>`