import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private rightSidebarContainer = new BehaviorSubject<ViewContainerRef | null>(null);
  private leftSidebarContainer = new BehaviorSubject<ViewContainerRef | null>(null);

  constructor(
    private resolver: ComponentFactoryResolver,
    private http: HttpClient
  ) {}

  public setSidebarContainer(sidebarContainer: ViewContainerRef, side: 'left' | 'right'): void {
    if (side === 'left') {
      this.leftSidebarContainer.next(sidebarContainer);
    } else {
      this.rightSidebarContainer.next(sidebarContainer);
    }
  }

  public async addComponent<T>(component: Type<T>, side: 'left' | 'right', position: number | null = null): Promise<ComponentRef<T>> {
    const factory = this.resolver.resolveComponentFactory(component);
    const containerSubject = side === 'left' ? this.leftSidebarContainer : this.rightSidebarContainer;
    const container = (await containerSubject

      .pipe(
        filter((container) => container !== null),
        first(),
      )
      .toPromise()) as ViewContainerRef;

    const componentInstance = factory.create(container.injector);
    container.insert(componentInstance.hostView);
    if (position !== null) {
      container.move(componentInstance.hostView, position);
    }
    return componentInstance;
  }

  public getSportsFilterSportsAndLeaguesValues(): Observable<any> {
    const path = 'api/v1/dictionary/filters';
    return this.http
      .get<any>(`${environment.devUvLink}${path}`)
      .pipe(
        map((res) => res)
      )
  }
}
