import { Injectable } from '@angular/core';
import { State, StateContext, Selector, Action } from '@ngxs/store';
import { Country } from 'src/app/shared/models/country.model';
import { Entity } from 'src/app/shared/models/entity.model';
import { StoreCategory } from 'src/app/shared/models/store-category.model';
import { StoreProduct } from 'src/app/shared/models/store-product.model';
import { PublicStoreStudio } from 'src/app/shared/models/studio.model';
import { ProductFilter } from 'src/app/shared/models/filter-form.model';
import { SetProductFilter, SetStudios } from './store.actions';

interface OnlineStore {
  studios: PublicStoreStudio[];
  productFilter: ProductFilter;
  selectedProduct: StoreProduct | null;
}

const defaultState = (): OnlineStore => ({
  studios: [],
  productFilter: { categories: [], countries: [], sportStyles: [], studios: [] },
  selectedProduct: null,
});

@State<OnlineStore>({
  name: 'store',
  defaults: defaultState(),
})
@Injectable()
export class OnlineStoreState {
  @Selector()
  static studios(state: OnlineStore): PublicStoreStudio[] {
    return state.studios;
  }

  @Selector()
  static categoriesInStudios(state: OnlineStore): (studioIds: number[]) => StoreCategory[] {
    return (studioIds: number[]) => {
      return state.studios.reduce<StoreCategory[]>((categories, studio) => {
        if (studioIds.includes(studio.id)) {
          return categories.concat(studio.categories);
        }

        return categories;
      }, []);
    };
  }

  @Selector()
  static selectedStudio(state: OnlineStore): PublicStoreStudio | null {
    return (state.productFilter.studios.length === 1 && state.productFilter.studios[0]) || null;
  }

  @Selector()
  static selectedCategory(state: OnlineStore): Entity | null {
    return (state.productFilter.categories.length === 1 && state.productFilter.categories[0]) || null;
  }

  @Selector()
  static countries(state: OnlineStore): Country[] {
    return state.studios.reduce((countries, studio) => {
      if (countries.some((country) => country.id === studio.country?.id)) {
        return countries;
      }

      return [...countries, studio.country];
    }, <Country[]>[]);
  }

  @Selector()
  static categories(state: OnlineStore): StoreCategory[] {
    return state.studios.reduce((categories: any, studio) => {
      return [...categories, ...studio.categories];
    }, <StoreCategory[]>[]);
  }

  @Selector([OnlineStoreState.studios, OnlineStoreState.countries])
  static productFilter(state: OnlineStore, allStudios: PublicStoreStudio[], allCountries: Country[]): ProductFilter {
    const countries = state.productFilter.countries.length ? state.productFilter.countries : allCountries;
    const studios = state.productFilter.studios.length ? state.productFilter.studios : allStudios;
    const filtredStudios = studios.filter((studio) => countries.some((country) => country.id === studio.country.id));
    return { ...state.productFilter, countries, studios: filtredStudios };
  }

  @Action(SetProductFilter)
  private SetProductFilter({ patchState }: StateContext<OnlineStore>, { productFilter }: SetProductFilter) {
    patchState({ productFilter });
  }

  @Action(SetStudios)
  private SetStudios({ patchState }: StateContext<OnlineStore>, { studios }: SetStudios) {
    patchState({ studios });
  }
}
