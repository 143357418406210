import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
//import { UserState } from 'studio/app/common/stores/user/user.store';
import { from, Observable } from 'rxjs';
import { first, publishReplay, refCount } from 'rxjs/operators';
import { SupportedLocales, DEFAULT_LOCALE } from "src/app/shared/constants/supported-locales";
import {OutsideClickDirective} from "../../shared/directives/outside-click.directive";
import {LocalizationModule} from "../localization";
import {CommonModule} from "@angular/common";
import {IconArrowDown} from "../../../assets/icons/arrow-down";
import {SafePipe} from "../../shared/pipes/safe.pipe";
import {SafeTypes} from "../../shared/constants/safe-types.enum";
import {UserSettingsComponent} from "../user-settings/user-settings.component";


@Component({
  standalone: true,
  selector: 'studio-local-selector',
  templateUrl: './local-selector.component.html',
  styleUrls: ['./local-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    OutsideClickDirective,
    LocalizationModule,
    CommonModule,
    SafePipe,
    UserSettingsComponent,
  ]
})
export class LocalSelectorComponent {
  //@Select(UserState.isAdmin)
  public isAdmin$!: Observable<boolean>;

  public SupportedLocales = SupportedLocales;
  public IconArrowDown = IconArrowDown;
  public SafeTypes = SafeTypes;
  @HostBinding('class.white-theme')
  @Input() isDiffUrl!: boolean;
  isWhiteTheme = false;

  @Input() hidePanelsOnOpenSearch!: boolean

  public locales = [
    {
      locale: SupportedLocales.en,
      label: 'ENGLISH',
      short: 'EN',
    },
    {
      locale: SupportedLocales.es,
      label: 'ESPAÑOL',
      short: 'ES',
    },
    {
      locale: SupportedLocales.de,
      label: 'DEUTSCH',
      short: 'DE',
    },
    {
      locale: SupportedLocales.fr,
      label: 'FRANÇAIS',
      short: 'FR',
    },
    {
      locale: SupportedLocales.ja,
      label: '日本人',
      short: 'JA',
    },
    {
      locale: SupportedLocales.ru,
      label: 'РУССКИЙ',
      short: 'RU',
    },
    {
      locale: SupportedLocales.ua,
      label: 'УКРАЇНСЬКА',
      short: 'UA',
    },
  ];

  public isShowMenu = false;

  constructor(
    private translocoService: TranslocoService
  ) {}

  public getFlags(): Observable<{ [key: string]: string }> {
    return from(import('../localization/country-flag-icons/js') as unknown as Promise<{ [key: string]: string }>).pipe(
      first(),
      publishReplay(1),
      refCount(),
    );
  }

  public outsideClick(isOwnClick: boolean) {
    if (!isOwnClick) {
      this.isShowMenu = false;
    }
  }

  changeLocale(selectedLang: SupportedLocales) {
    if (selectedLang === SupportedLocales.raw) {
      this.translocoService.setDefaultLang(selectedLang);
      this.translocoService.setFallbackLangForMissingTranslation({ fallbackLang: selectedLang });
      this.translocoService.setActiveLang(selectedLang);
      this.isShowMenu = false;
      return;
    }

    if (this.translocoService.getDefaultLang() !== DEFAULT_LOCALE) {
      this.translocoService.setDefaultLang(DEFAULT_LOCALE);
      this.translocoService.setFallbackLangForMissingTranslation({ fallbackLang: DEFAULT_LOCALE });
    }

    localStorage.setItem('locale', selectedLang);
    this.translocoService.setActiveLang(selectedLang);
    this.isShowMenu = false;
  }
}
