import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { Logout } from '../../store/user/user.actions';
import { Router } from '@angular/router';
import { UserState } from '../../store/user/user.store';
import app from 'package.json';
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private store: Store, private router: Router) {}

  intercept(request: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.store.selectSnapshot(UserState.authToken);
    const appversion = app.version.replace(/\./g, '');

    const setHeaders: any = { appversion };

    if (authToken && authToken.token) {
      setHeaders['authorization'] = `Bearer ${authToken.token}`;
    }

    setHeaders['Sportshub-Subdomain'] = location.host;

    request = request.clone({ setHeaders });

    return next.handle(request).pipe(
      catchError((error, caught) => {
        // We MUST catch Session Expired Error only in case of Authorized Requests
        if (authToken && authToken.token && error instanceof HttpErrorResponse && error.status === 401) {
          this.toastr.error('Your session expired! Please, Sign In again');
          this.store.dispatch(Logout).subscribe(() => {
            this.router.navigateByUrl('/events');
          });
        }

        if (error instanceof HttpErrorResponse && error.error && error.error.errorCode === 'TOO_LOW_APP_VERSION') {
          this.toastr.warning('Please update your application. Current version is no longer supported');
        }

        return throwError(error);
      }),
    );
  }
}
