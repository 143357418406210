import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {AuthContract} from "../../shared/contracts/auth.contract";

@Injectable()
export class AuthTokenResolver implements Resolve<any> {
  constructor(
    private authContract: AuthContract,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const {token} = route.params;
    const tokenPassObservable: Observable<any> = new Observable(subscriber => {
      subscriber.next(token);
      subscriber.complete();
    });
    return forkJoin([
      // this.authContract.getAuthByToken(token),
      tokenPassObservable
    ]);
  }
}
