import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    UrlTree
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserState } from '../../store/user/user.store';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EventRegistrationGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
        return this.store.select(UserState.authToken).pipe(
            map((authToken) => {
                const landingUrl = `/events/event/${route.params['eventId']}`;
                return Boolean(authToken?.token) || this.router.parseUrl(landingUrl);
            }),
        );
    }
}
