export enum AvailableLangs {
  ar = 'ar-AE',
  cs = 'cs-CZ',
  en = 'en-US',
  es = 'es-ES',
  fr = 'fr-FR',
  ja = 'ja-JP',
  pt = 'pt-PT',
  ru = 'ru-RU',
  tr = 'tr-TR',
  uk = 'uk-UA',
  de = 'de-DE',
  raw = 'raw',
}

export const LanguagesList = [
  AvailableLangs.en,
  AvailableLangs.es,
  AvailableLangs.fr,
  AvailableLangs.ja,
  AvailableLangs.ru,
  AvailableLangs.uk,
  AvailableLangs.ar,
  AvailableLangs.cs,
  AvailableLangs.pt,
  AvailableLangs.tr,
  AvailableLangs.de,
];

export const DefaultLang = AvailableLangs.en;

export const LocaleLabels = [
  {
    locale: AvailableLangs.en,
    label: 'ENGLISH',
  },
  {
    locale: AvailableLangs.es,
    label: 'ESPAÑOL',
  },
  {
    locale: AvailableLangs.fr,
    label: 'FRANÇAIS',
  },
  {
    locale: AvailableLangs.ja,
    label: '日本人',
  },
  {
    locale: AvailableLangs.ru,
    label: 'РУССКИЙ',
  },
  {
    locale: AvailableLangs.uk,
    label: 'УКРАЇНСЬКА',
  },
  {
    locale: AvailableLangs.ar,
    label: 'عربي',
  },
  {
    locale: AvailableLangs.cs,
    label: 'Čeština',
  },
  {
    locale: AvailableLangs.pt,
    label: 'Português',
  },
  {
    locale: AvailableLangs.tr,
    label: 'Türk',
  },
  {
    locale: AvailableLangs.raw,
    label: 'RAW',
  },
];
