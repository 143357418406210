import { Component } from '@angular/core';
import { IconUvFooter } from 'src/assets/icons/footer-logo';
import { IconTwitter } from 'src/assets/icons/twitter';
import { IconFacebook } from 'src/assets/icons/facebook';
import { IconInstagram } from 'src/assets/icons/instagram';
import { IconYoutube } from 'src/assets/icons/youtube';
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../shared/constants/safe-types.enum";

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [SafePipe]
})
export class FooterComponent {
  public SafeTypes = SafeTypes;
  public IconUvFooter = IconUvFooter;
  public IconFacebook = IconFacebook;
  public IconTwitter = IconTwitter;
  public IconInstagram = IconInstagram;
  public IconYoutube = IconYoutube;
}
