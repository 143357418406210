export const IconFlag = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
<g clip-path="url(#clip0_97_1391)">
  <path d="M2 1C2 0.446875 1.55313 0 1 0C0.446875 0 0 0.446875 0 1V2V11.5V15C0 15.5531 0.446875 16 1 16C1.55313 16 2 15.5531 2 15V11L4.00938 10.4969C5.29375 10.175 6.65312 10.325 7.8375 10.9156C9.21875 11.6062 10.8219 11.6906 12.2656 11.1469L13.35 10.7406C13.7406 10.5938 14 10.2219 14 9.80313V2.06562C14 1.34687 13.2438 0.878125 12.6 1.2L12.3 1.35C10.8531 2.075 9.15 2.075 7.70312 1.35C6.60625 0.8 5.34688 0.6625 4.15625 0.959375L2 1.5V1Z" />
</g>
<defs>
  <clipPath id="clip0_97_1391">
    <rect width="14" height="16" fill="white"/>
  </clipPath>
</defs>
</svg>`;
