<button mat-button [matMenuTriggerFor]="menu" class="user-icon">
    <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #menu="matMenu" [xPosition]="'before'" class="user-action-mennu">
    <div class="user">
        <div class="user-icon">
            <mat-icon>account_circle</mat-icon>
        </div>
        <div class="user-info">
            <div class="name">{{ user.fullName ? user.fullName : user.firstName + ' ' + user.lastName}}</div>
            <div class="role" *ngFor="let role of user.studioAccount?.roles">{{role.name | transloco }}</div>
            <div class="email">
                <a href="mailto:{{ user.email }}">{{ user.email }}</a>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="actions">
        <button mat-menu-item (click)="openMeetDialog('0ms', '0ms')">
            <div [innerHTML]="IconFlag | safe: SafeTypes.html" class="action-icon"></div>
            <span class="action-title">{{'MEETS' | transloco}}</span>
        </button>
        <button mat-menu-item (click)="openEventDialog('0ms', '0ms')">
            <div [innerHTML]="IconEvents | safe: SafeTypes.html" class="action-icon"></div>
            <span class="action-title">{{'EVENTS' | transloco}}</span>
        </button>
        <button mat-menu-item disabled>
            <div [innerHTML]="IconStudios | safe: SafeTypes.html" class="action-icon"></div>
            <span class="action-title">{{'STUDIOS' | transloco}}</span>
        </button>
        <button mat-menu-item (click)="dashboard()">
            <div [innerHTML]="IconHome | safe: SafeTypes.html" class="action-icon"></div>
            <span class="action-title">{{'DASHBOARD' | transloco}}</span>
        </button>
        <mat-divider class="bottom-divider"></mat-divider>
        <button mat-menu-item (click)="logout()">
            <div [innerHTML]="IconSignOut | safe: SafeTypes.html" class="action-icon"></div>
            <span class="action-title">{{'SIGN_OUT' | transloco}}</span>
        </button>
    </div>
</mat-menu>