import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { LocalizationModule } from "../localization";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslocoService } from "@ngneat/transloco";
import { DialogRef } from "@angular/cdk/dialog";
import { SignInComponent } from "./signin/signin.component";
import { SignUpComponent } from "./signup/signup.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/shared/services/auth.service";

export enum SignInSignUpOptions {
  signin = "signin",
  signup = "signup",
  forgotPassword = "forgotPassword",
}

@Component({
  standalone: true,
  selector: "events-sign-in-sign-up",
  templateUrl: "./signin-signup.component.html",
  styleUrls: ["./signin-signup.component.scss"],
  imports: [
    CommonModule,
    LocalizationModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
  ],
  providers: [TranslocoService],
})
export class SignInSignUpComponent implements OnInit, OnDestroy {
  forgotPasswordVisible = false;
  forgotPasswordResultVisible = false;
  displayIncorrectEmailOrPassword = false;

  public SignInSignUpOptions = SignInSignUpOptions;
  public activeOption$: SignInSignUpOptions = SignInSignUpOptions.signin;

  signInAndClose = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private transloco: TranslocoService,
    public dialogRef: DialogRef<string>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signInAndClose =
      data && data.signInAndClose ? data.signInAndClose : false;
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  public goToRegister() {
    this.router.navigateByUrl("/register");
  }

  resetPassword() {
    /*const email = (this.resetByEmailController.value as string).trim();
    this.authController.resetPassword(email).subscribe(
      (data) => {
        this.hideForgotPassword();
          this.toastr.success(data.message);
        if (data.reset) {
          this.close.emit();
        }
        if (!data.reset) {
          this.toastr.error(data.message);
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      },
    );*/
  }

  closeClick() {
    this.dialogRef.close();
  }

  signInSuccessed() {
    //const landingUrl = this.store.selectSnapshot(UserState.landingUrl);
    //this.router.navigateByUrl(landingUrl);
    this.dialogRef.close();
  }

  signUpSuccessed() {
    this.dialogRef.close();
  }

  displaySignUp() {
    this.activeOption$ = SignInSignUpOptions.signup;
  }

  displaySignIn() {
    this.activeOption$ = SignInSignUpOptions.signin;
  }

  displayForgotPassword() {
    this.activeOption$ = SignInSignUpOptions.forgotPassword;
  }
}
