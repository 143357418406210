import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Currency } from 'src/app/shared/models/currency.model';
import { Country } from "src/app/shared/models/country.model";
import { State } from "src/app/shared/models/state.model";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class DictionariesContract {
  constructor(private http: HttpClient) {}

  public getCurrencies() {
    //return this.http.get<Currency[]>(`${environment.studioApi}api/v1/dictionary/currency`, { headers: { appversion: '200' } });
    return this.http.get<Currency[]>(`${environment.APIURL}api/public/dictionary/studio/currency`);
  }

  public getCountries(name: string = '') {
    const filter = {
      page: 0,
      size: 1000,
      filter: {
        name,
      },
    };

    return this.http.post<Country[]>(`${environment.APIURL}api/public/dictionary/event/countries/filter`, filter);
  }

  public getStatesOrProvinces(countryId: number, name: string = '') {
    const filter = {
      page: 0,
      size: 1000,
      filter: {
        name,
        countryId,
      },
    };

    return this.http.post<State[]>(`${environment.APIURL}api/public/dictionary/event/states/filter`, filter);
  }
}
