export const IconCreateLicensedMeet = `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
<path d="M58.8817 9.97976V19.5854H3.11914V9.97976C3.11914 8.5439 3.92751 7.29642 5.11512 6.67018C5.63656 6.39324 6.23037 6.2373 6.8616 6.2373H55.1393C56.5751 6.2373 57.8226 7.04567 58.4488 8.23328C58.7258 8.75473 58.8817 9.34853 58.8817 9.97976Z" fill="#01A1DA"/>
<path d="M18.0889 6.2373V13.2232C18.0889 13.9118 17.53 14.4707 16.8414 14.4707H11.103C10.4143 14.4707 9.85547 13.9118 9.85547 13.2232V6.2373H18.0889Z" fill="#0088B8"/>
<path d="M50.1494 6.2373V13.2232C50.1494 13.9118 49.5905 14.4707 48.9019 14.4707H43.1635C42.4749 14.4707 41.916 13.9118 41.916 13.2232V6.2373H50.1494Z" fill="#0088B8"/>
<path d="M5.11512 6.67041V19.5856H3.11914V9.97999C3.11914 8.54413 3.92751 7.29665 5.11512 6.67041Z" fill="#0088B8"/>
<path d="M13.3484 27.0479H19.7667C20.1111 27.0479 20.3904 27.3272 20.3904 27.6716V34.0899C20.3904 34.4343 20.1111 34.7136 19.7667 34.7136H13.3484C13.0039 34.7136 12.7246 34.4343 12.7246 34.0899V27.6716C12.7246 27.3272 13.0039 27.0479 13.3484 27.0479Z" fill="#E6E6E6"/>
<path d="M13.3484 36.585H19.7667C20.1111 36.585 20.3904 36.8643 20.3904 37.2087V43.6283C20.3904 43.9727 20.1111 44.252 19.7667 44.252H13.3484C13.0039 44.252 12.7246 43.9727 12.7246 43.6283V37.2087C12.7246 36.8643 13.0039 36.585 13.3484 36.585Z" fill="#E6E6E6"/>
<path d="M13.3484 46.123H19.7667C20.1111 46.123 20.3904 46.4024 20.3904 46.7468V53.1651C20.3904 53.5095 20.1111 53.7888 19.7667 53.7888H13.3484C13.0039 53.7888 12.7246 53.5095 12.7246 53.1651V46.7468C12.7246 46.4024 13.0039 46.123 13.3484 46.123Z" fill="#E6E6E6"/>
<path d="M22.8855 27.0479H29.441C29.7854 27.0479 30.0647 27.3272 30.0647 27.6716V34.0899C30.0647 34.4343 29.7854 34.7136 29.441 34.7136H22.8855C22.541 34.7136 22.2617 34.4343 22.2617 34.0899V27.6716C22.2617 27.3272 22.541 27.0479 22.8855 27.0479Z" fill="#E6E6E6"/>
<path d="M22.8855 36.585H29.441C29.7854 36.585 30.0647 36.8643 30.0647 37.2087V43.6283C30.0647 43.9727 29.7854 44.252 29.441 44.252H22.8855C22.541 44.252 22.2617 43.9727 22.2617 43.6283V37.2087C22.2617 36.8643 22.541 36.585 22.8855 36.585Z" fill="#E6E6E6"/>
<path d="M22.8855 46.123H29.441C29.7854 46.123 30.0647 46.4024 30.0647 46.7468V53.1651C30.0647 53.5095 29.7854 53.7888 29.441 53.7888H22.8855C22.541 53.7888 22.2617 53.5095 22.2617 53.1651V46.7468C22.2617 46.4024 22.541 46.123 22.8855 46.123Z" fill="#E6E6E6"/>
<path d="M32.5593 27.0479H39.1148C39.4593 27.0479 39.7386 27.3272 39.7386 27.6716V34.0899C39.7386 34.4343 39.4593 34.7136 39.1148 34.7136H32.5593C32.2149 34.7136 31.9355 34.4343 31.9355 34.0899V27.6716C31.9355 27.3272 32.2149 27.0479 32.5593 27.0479Z" fill="#E6E6E6"/>
<path d="M32.5593 36.585H39.1148C39.4593 36.585 39.7386 36.8643 39.7386 37.2087V43.6283C39.7386 43.9727 39.4593 44.252 39.1148 44.252H32.5593C32.2149 44.252 31.9355 43.9727 31.9355 43.6283V37.2087C31.9355 36.8643 32.2149 36.585 32.5593 36.585Z" fill="#E6E6E6"/>
<path d="M32.5593 46.123H39.1148C39.4593 46.123 39.7386 46.4024 39.7386 46.7468V53.1651C39.7386 53.5095 39.4593 53.7888 39.1148 53.7888H32.5593C32.2149 53.7888 31.9355 53.5095 31.9355 53.1651V46.7468C31.9355 46.4024 32.2149 46.123 32.5593 46.123Z" fill="#E6E6E6"/>
<path d="M49.2752 27.6716V34.0899C49.2752 34.4343 48.9959 34.7136 48.6514 34.7136H42.2331C41.8887 34.7136 41.6094 34.4343 41.6094 34.0899V27.6716C41.6094 27.3272 41.8887 27.0479 42.2331 27.0479H48.6514C48.9959 27.0479 49.2752 27.3272 49.2752 27.6716Z" fill="#E6E6E6"/>
<path d="M42.2331 36.585H48.6514C48.9959 36.585 49.2752 36.8643 49.2752 37.2087V43.6283C49.2752 43.9727 48.9959 44.252 48.6514 44.252H42.2331C41.8887 44.252 41.6094 43.9727 41.6094 43.6283V37.2087C41.6094 36.8643 41.8887 36.585 42.2331 36.585Z" fill="#E6E6E6"/>
<path d="M42.2331 46.123H48.6514C48.9959 46.123 49.2752 46.4024 49.2752 46.7468V53.1651C49.2752 53.5095 48.9959 53.7888 48.6514 53.7888H42.2331C41.8887 53.7888 41.6094 53.5095 41.6094 53.1651V46.7468C41.6094 46.4024 41.8887 46.123 42.2331 46.123Z" fill="#E6E6E6"/>
<path d="M3.11914 18.7124V58.2577C3.11914 60.3248 4.79451 62.0001 6.8616 62.0001H55.1393C57.2063 62.0001 58.8817 60.3248 58.8817 58.2577V18.7124H3.11914Z" fill="#F5F5F5"/>
<path d="M18.089 1.24748V11.2274C18.089 11.916 17.5301 12.4748 16.8415 12.4748H13.099C12.4104 12.4748 11.8516 11.916 11.8516 11.2274V1.24748C11.8516 0.558873 12.4104 0 13.099 0H16.8415C17.5301 0 18.089 0.558873 18.089 1.24748Z" fill="#6B6B6B"/>
<path d="M12.7246 27.0479H20.3904V34.7136H12.7246V27.0479Z" fill="#FFAC5C"/>
<path d="M41.6094 27.0479H49.2752V34.7136H41.6094V27.0479Z" fill="#FFAC5C"/>
<path d="M22.2617 27.0479H30.0647V34.7136H22.2617V27.0479Z" fill="#FFAC5C"/>
<path d="M12.7246 36.585H20.3904V44.252H12.7246V36.585Z" fill="#FFAC5C"/>
<path d="M41.6094 36.585H49.2752V44.252H41.6094V36.585Z" fill="#E6E6E6"/>
<path d="M22.2617 36.585H30.0647V44.252H22.2617V36.585Z" fill="#FFAC5C"/>
<path d="M12.7246 46.123H20.3904V53.7888H12.7246V46.123Z" fill="#E6E6E6"/>
<path d="M41.6094 46.123H49.2752V53.7888H41.6094V46.123Z" fill="#E6E6E6"/>
<path d="M22.2617 46.123H30.0647V53.7888H22.2617V46.123Z" fill="#E6E6E6"/>
<path d="M31.9375 27.0479H39.7405V34.7136H31.9375V27.0479Z" fill="#FFAC5C"/>
<path d="M31.9375 36.585H39.7405V44.252H31.9375V36.585Z" fill="#FFAC5C"/>
<path d="M31.9375 46.123H39.7405V53.7888H31.9375V46.123Z" fill="#E6E6E6"/>
<path d="M50.1495 1.24748V11.2274C50.1495 11.916 49.5907 12.4748 48.902 12.4748H45.1596C44.471 12.4748 43.9121 11.916 43.9121 11.2274V1.24748C43.9121 0.558873 44.471 0 45.1596 0H48.902C49.5907 0 50.1495 0.558873 50.1495 1.24748Z" fill="#6B6B6B"/>
<path d="M3.11914 18.7124V58.2577C3.11914 60.3248 4.79451 62.0001 6.8616 62.0001H8.24381C6.49858 61.6833 5.17749 60.1563 5.17749 58.32V21.3596C5.31846 20.9791 5.68397 20.7084 6.1131 20.7084H58.8817V18.7748V18.7124H3.11914Z" fill="#E6E6E6"/>
<path d="M15.095 12.4748H13.099C12.4104 12.4748 11.8516 11.916 11.8516 11.2274V1.24748C11.8516 0.558873 12.4104 0 13.099 0H13.8475V11.2274C13.8475 11.916 14.4064 12.4748 15.095 12.4748Z" fill="#454545"/>
<path d="M47.1556 12.4748H45.1596C44.471 12.4748 43.9121 11.916 43.9121 11.2274V1.24748C43.9121 0.558873 44.471 0 45.1596 0H45.9081V11.2274C45.9081 11.916 46.467 12.4748 47.1556 12.4748Z" fill="#454545"/>
</svg>`