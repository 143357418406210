export const IconStudios = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<g clip-path="url(#clip0_97_1335)">
  <path d="M9 8C9 8.55313 8.55313 9 8 9C7.44688 9 7 8.55313 7 8C7 7.44688 7.44688 7 8 7C8.55313 7 9 7.44688 9 8ZM3.5 8C3.5 5.51562 5.51562 3.5 8 3.5C10.4844 3.5 12.5 5.51562 12.5 8C12.5 10.4844 10.4844 12.5 8 12.5C5.51562 12.5 3.5 10.4844 3.5 8ZM8 10.5C9.38125 10.5 10.5 9.38125 10.5 8C10.5 6.61875 9.38125 5.5 8 5.5C6.61875 5.5 5.5 6.61875 5.5 8C5.5 9.38125 6.61875 10.5 8 10.5ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 2C4.65938 2 2 4.65938 2 8C2 11.3125 4.65938 14 8 14C11.3125 14 14 11.3125 14 8C14 4.65938 11.3125 2 8 2Z" fill="#BAB9BA"/>
</g>
<defs>
  <clipPath id="clip0_97_1335">
    <rect width="16" height="16" fill="white"/>
  </clipPath>
</defs>
</svg>`;
