import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { IconArrowUp } from 'src/assets/icons/arrow-up';
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../shared/constants/safe-types.enum";
import { TranslocoModule } from "@ngneat/transloco";

export enum FilterType {
  countries = 'countries',
  leagues = 'leagues',
  sports = 'sports',
}

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslocoModule,
  ],
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() title!: string;
  @Input() maxItems!: number;
  //@Input() values!: any[];
  @Input() type!: FilterType;

  _values: any[] = [];

  get values(): any[] {
    return this._values;
  }

  @Input() set values(values: any[]) {
    this._values = values;
    this.updateItems();
  }

  _filteredItems: any[] = [];

  get filteredItems(): any[] {
    return this._filteredItems;
  }

  @Input() set filteredItems(items: any[]) {
    if (this.items && (this.items.length > 0)) {
      const ids = this.items.map((item) => item.id);

      const filteredItems: any[] = [];
      if (items && (items.length > 0)) {
        items.map((item) => {
          if (!ids.includes(item.id)) {
            filteredItems.push(item);
          }
        });
      }

      this._filteredItems = filteredItems;
    } else {
      this._filteredItems = items;
    }
  }

  @Output() filterItemSelected = new EventEmitter();
  @Output() search = new EventEmitter();

  protected readonly FilterType = FilterType;

  public searchText!: string;
  public IconArrowUp = IconArrowUp;
  public SafeTypes = SafeTypes;

  public items: any[] = [];
  selectedItemIds: any[] = [];

  searchTimeout: any;

  constructor() { }

  public ngOnInit(): void {
  }

  onSearchChange(search: any) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.items = this.filterItemsByName(search);
      this.search.emit(search);
    }, 500);
  }

  updateItems() {
    this.items = this.filterItemsByName(this.searchText);
  }

  filterItemsByName(search: string) {
    const items = [...this._values];
    if (!(search && (search.length > 0))) {
      return items;
    }

    return items.filter((item: any) => (item.name ? item.name.toLowerCase().trim().includes(search) : false));
  }

  selectItem(item: any) {
    const selectedItems = [...this.selectedItemIds];
    if (!selectedItems.includes(item.id)) {
      selectedItems.push(item.id);
      this.selectedItemIds = selectedItems;
      this.filterItemSelected.emit(this.selectedItemIds);
    }
  }

  deselectItem(item: any) {
    const selectedItems = [...this.selectedItemIds];
    if (selectedItems.includes(item.id)) {
      const index = selectedItems.indexOf(item.id);
      if (index >= 0) {
        selectedItems.splice(index, 1)
        this.selectedItemIds = selectedItems;
        this.filterItemSelected.emit(this.selectedItemIds);
      }
    }
  }

  isItemSelected(item: any) {
    return (this.selectedItemIds ? this.selectedItemIds.includes(item.id) : false);
  }

  clearFilters() {
    this.selectedItemIds = [];
    this.filterItemSelected.emit(null);
  }
}
